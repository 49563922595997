import { VerifyEmailComponent } from './update-email/verify-email/verify-email.component';
import { DisableDeepLinksGuard } from './common/authentication/disable-deeplinks.guard';
import { UnauthorizedComponent } from './common/shared-components/unauthorized/unauthorized.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LandingPageComponent } from './home/landing-page/landing-page.component';
import { LoginDayOneComponent } from './login-day-one/login-day-one.component';
import * as pagesConfig from '../config/pages-config';
import { GeneralErrorComponent } from './common/shared-components/general-error/general-error.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgetUsernameComponent } from './forget-username/forget-username.component';
import { AuthenticationGuard } from '@vfde-fe/ng-modules';
import { DisableFeatureGuard } from '@vfde-fe/ng-modules';

const routes: Routes = [

  {
    path: '',
    redirectTo: pagesConfig.config.accountManagement.accountOverview.route,
    pathMatch: 'full',
  },
  {
    // Registration
    path: pagesConfig.config.registration.name,
    loadChildren: './registration/registration.module#RegistrationModule',
  },
  {
    // Add Contract
    path: pagesConfig.config.addContract.name,
    loadChildren: './add-contract/add-contract.module#AddContractModule',
    canActivate: [AuthenticationGuard],
  },
  {
    // First Login
    path: pagesConfig.config.firstLogin.name,
    loadChildren: './first-login/first-login.module#FirstLoginModule',
    canActivate: [DisableDeepLinksGuard],
  },
  {
    // Login
    path: pagesConfig.config.login.name,
    loadChildren: './login/login.module#LoginModule',
  },
//   {
//     // Demo
//     path: pagesConfig.config.demo.name,
//     loadChildren: './demo/demo.module#DemoModule'
//   },
  {
    // Login Day One
    path: pagesConfig.config.loginDayOne.name,
    loadChildren: './login-day-one/login-day-one.module#LoginDayOneModule',
    canActivate: [DisableDeepLinksGuard],
  },
  {
    // RequestActivationCode
    path: pagesConfig.config.requestActivationCode.name,
    loadChildren: './request-activation-code/request-activation-code.module#RequestActivationCodeModule',
  },
  {
    // account-management
    path: pagesConfig.config.accountManagement.name,
    loadChildren: './account-management/account-management.module#AccountManagementModule',
    canActivate: [AuthenticationGuard],
  },
  {
    // edit-access
    path: pagesConfig.config.editAccess.name,
    loadChildren: './edit-access/edit-access.module#EditAccessModule',
    canActivate: [DisableDeepLinksGuard],
  },
  {
    // update-email
    path: pagesConfig.config.updateEmail.name,
    loadChildren: './update-email/update-email.module#UpdateEmailModule',
  },
  {
    // remove-email
    path: pagesConfig.config.removeEmail.name,
    loadChildren: './remove-email/remove-email.module#RemoveEmailModule',
  },

  //  SEPERATE COMPONENTS
//   {
//     // Home
//     path: pagesConfig.config.home.name,
//     loadChildren: './home/home.module#HomeModule'
//   },
  {
    // Unauthorized
    path: pagesConfig.config.unauthorized.name,
    component: UnauthorizedComponent,
    pathMatch: 'full',
  },
  {
    // general error handleing page
    path: pagesConfig.config.generalError.name,
    component: GeneralErrorComponent,
    pathMatch: 'full',
    canActivate: [DisableDeepLinksGuard],
  },
  {
    // forget password
    path: pagesConfig.config.forgetPassword.name,
    loadChildren: './forget-password/forget-password.module#ForgetPasswordModule',
  },
  {
    // forget username
    path: pagesConfig.config.forgetUsername.name,
    loadChildren: './forget-username/forget-username.module#ForgetUsernameModule',
  },
  {
    // merge user
    path: pagesConfig.config.mergeUser.name,
    loadChildren: './merge-user/merge-user.module#MergeUserModule',
    canActivate: [AuthenticationGuard],
  },
  {
    // enterprise
    path: pagesConfig.config.enterprise.name,
    loadChildren: './enterprise/enterprise.module#EnterpriseModule',
  },
  {
    // accept user invitation
    path: pagesConfig.config.acceptUserInvitation.name,
    loadChildren: './accept-user-invitation/accept-user-invitation.module#AcceptUserInvitationModule',
  },
  {
    // Change ONB Username
    path: pagesConfig.config.changeONBUsername.name,
    loadChildren: './change-onb-username/change-onb-username.module#ChangeONBUsernameModule',
    canActivate: [DisableDeepLinksGuard],
  },
  {
    // topup payment
    path: pagesConfig.config.topupPayment.name,
    loadChildren: './topup-payment/topup-payment.module#TopupPaymentModule',
  },
  {
    // Gdpr
    path: pagesConfig.config.gdpr.name,
    loadChildren: './gdpr/gdpr.module#GdprModule',
    canActivate: [AuthenticationGuard],
  },
  {
    // check email verfied
    path: pagesConfig.config.checkEmailValidation.name,
    loadChildren: './validate-email/validate-email.module#ValidateEmailModule',
  },
  {
    // Old payment
    path: pagesConfig.config.paymentProvider.name,
    loadChildren: './payment-provider/payment-provider.module#PaymentProviderModule',
  },

];

/** AppRoutingModule */
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false})],
  exports: [RouterModule],
})

export class AppRoutingModule { }
