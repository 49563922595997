import { Injectable } from '@angular/core';
import { Helper } from '@vfde-fe/ng-modules';
import { passwordStrength } from './ol-password.strength.enum';

/** OlPasswordValidationService */
@Injectable()

export class OlPasswordValidationService {

    /**
     * constructor
     * @param helper
     */
    constructor (private helper: Helper) {
    }
    /** passwordStrengthValidation */
    public passwordStrengthValidation (password: string) {
        if (this.isStrong(password)) {
            return passwordStrength.strong;
        }
        else if (this.isMeduim(password)) {
            return passwordStrength.medium;
        }
        else {
            return passwordStrength.weak;
        }
    }
    /** isStrong */
    public isStrong (Password: string) {
        const strongRegex = new RegExp(this.helper.getRegexByKey('strongPassword'));

        return strongRegex.test(Password);
    }
    /** isMeduim */
    public isMeduim (Password: string) {
        const mediumRegex = new RegExp(this.helper.getRegexByKey('mediumPassword'));

        return mediumRegex.test(Password);
    }
}
