import { environment } from '../../../environments/environment';
import { PROCESS_MODULE_KEY } from './Defines';

/** API_URLS */
export const API_URLS = {
    DEMO: {
        PROCESS_START_KEY: 'com.uxpsystems.mint.vodafone.process.frontend.demo',
        SPINNER_URL: environment.baseUrlNil + '/spinner',
        ERROR_SIMULATION: environment.baseUrlNil + '/errorhandlingtesting?errorNumber=',
    },
    REGISTRATION: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.REGISTRATION,
    },
    CHANGE_PASSWORD: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.CHANGE_PASSWORD,

    },
    REQUEST_ACTIVATION_CODE: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.REQUEST_ACTIVATION_CODE,
    },
    PROCESS: {
        START: environment.baseUrlMint + '/rest/process/start/',
        STEP: environment.baseUrlMint + '/rest/process/step',
    },
    ADDCONTRACT: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.ADD_CONTRACT,
    },
    ECARE_ROUTES: {
        DASHBOARD: '/meinvodafone/services/',
        CONTRACT_CHOOSER: '/meinvodafone/services/vertragsauswahl',
        QUICK_CHECK_TOPUP: '/meinvodafone/services/teilnehmer/quickcheck?accordion=kostendetails',
        CONTRACT_OVERVIEW_CUSTOMER_DATA_ACCORDION: '/meinvodafone/services/vertragsdaten?accordion=kundendaten-herunterladen',
        SUBSCRIPTION_OVERVIEW_SIM_CARD: '/meinvodafone/services/teilnehmer/datenschutz-einstellungen',
        CONTRACT_OVERVIEW_CPM_ACCORDION: '/meinvodafone/services/vertragsdaten?accordion=datenschutz-einstellungen',
    },
    USER: {
        USER_MINT: environment.baseUrlMint + '/rest/user',
        GET_USER_NIL: environment.baseUrlNil + '/enterprise-resources/core/bss/user-nil/identity/user-accounts/user-data',
        GET_USER_ACCOUNTS: environment.baseUrlMint + '/rest/user/accounts?embed=otherUsers',
        GET_USER_SUBSCRIPTIONS: environment.baseUrlMint + '/rest/user/subscriptions?embed=otherUsers',
        SUBMIT_ACTIVE_MBO_NIL: environment.baseUrlNil + '/enterprise-resources/core/bss/user-nil/identity/user-accounts/mbo/',
        SUBMIT_ACTIVE_MBO_MINT: environment.baseUrlMint + '/rest/user',
        GET_ENTITYID_FOR_MARTIN: environment.baseUrlMint + '/rest/account/byattribute/de.vodafone.account.accountNumber/{accountNumber}',
    },
    LOGIN: {
        SESSION_START: environment.baseUrlMint + '/rest/session/start', // for mdev use only
    },

    CONTRACT: {
        UPDATE: environment.baseUrlMint + '/rest/user/{userId}/account/{accountId}/association',
        DELETE: environment.baseUrlMint + '/rest/user/{userId}/account/{accountId}/association',
    },
    CHANGE_USERNAME: {
        UPDATE: environment.baseUrlMint + '/rest/user/username',

    },
    SUBSCRIPTION: {
        UPDATE: environment.baseUrlMint + '/rest/user/{userId}/subscription/{subscriptionId}/association',
        DELETE: environment.baseUrlMint + '/rest/user/{userId}/subscription/{subscriptionId}/association',
    },
    FIRST_LOGIN: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.FIRST_LOGIN,
    },
    ACCEPT_USER_INVITATION: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.ACCEPT_USER_INVITATION,
    },
    CHANGE_ONB_USERNAME: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.CHANGE_ONB_USERNAME,
    },
    EDIT_ACCESS: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.EDIT_ACCESS,
    },
    ACCOUNT_MANAGEMENT: {
        DELETE_ACCOUNT: environment.baseUrlMint + '/rest/user/{userId}',
        PROCESS_START_KEY: PROCESS_MODULE_KEY.CHANGE_SECURITY_QUESTIONS,
    },
    FORGET_PASSWORD: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.FORGET_PASSWORD,
    },
    RESET_PASSWORD_TOKEN: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.RESET_PASSWORD_TOKEN,

    },
    UPDATE_EMAIL: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.UPDATE_EMAIL,
        VERIFY_EMAIL_NIL: environment.baseUrlMint + '/rest/token/{token}?email={email}',
    },
    REMOVE_EMAIL: {
        REMOVE: environment.baseUrlMint + '/rest/subscription/OXMail',
    },
    FORGET_USERNAME: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.FORGET_USERNAME,
    },
    LOGIN_DAY_ONE: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.LOGIN_DAY_ONE,
    },
    MERGE_USER: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.MERGE_USER,
    },
    TAN_GENERATION: {
        SESSION_START: environment.baseUrlMint + '/rest/user/tan',
    },
    LOGOUT: {
        LOGOUT: environment.baseUrlMint + '/saml/logout',
    },
    SESSIONEND: environment.baseUrlMint + '/rest/session/end',
    PAYMENT: {
        PAYMENT_CONFIG: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/payment-management/v1/payment/config?usecase={usecase}&client-id={client-id}&channel-id={channel-id}',
        PAYMENT_METHOD: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/customer/customer-accounts/{id}/payment-methods?usecase={usecase}&client-id={client-id}&channel-id={channel-id}&id-type={id-type}',
        SHOPPER_STATUS_POST: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/payment-management/v1/payment/shopper-status',
        PAYMENT_HISTORY: (id, usecase, channelId, clientId, idType, startDate?, itemsPerPage?) => environment.baseUrlNil + `/enterprise-resources/core/bss/cus-nil/payment-management/v1/payment/${id}/history?usecase=${usecase}&client-id=${clientId}&channel-id=${channelId}&id-type=${idType}${startDate ? '&start-date=' + startDate : ''}${itemsPerPage ? '&items-per-page=' + itemsPerPage : ''}`,
        PAYMENT_CREATION_POST: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/payment-management/v1/payment',
        PAYMENT_STATUS_GET: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/payment-management/v1/payment/{payment-id}/status?usecase={usecase}&client-id={client-id}&channel-id={channel-id}',
        PAYMENT_STATUS_PUT: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/payment-management/v1/payment/{payment-id}/status',
    },
    GDPR: {
        HASHING_MBOS: environment.OTDSBaseUrl + '/hashing',
        GET_NBAPERMISSIONS: 'https://fc.vodafone.de/op/meinvf/info?permissions=&notifications=&out=json',
        CUSTOMER_DOWNLOAD_DATA: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/{type}/customer/customer-accounts/{number}/data?mode=csv',
        CUSTOMER_DOWNLOAD_DATA_STUBS: environment.baseUrlNil + '/enterprise-resources/core/bss/cus-nil/{type}/customer/customer-accounts/{number}/data?mode=csv',
    },
    CALLBACK: {
        CALLBACK_LIST: environment.gmchatServiceUrl + '/service/cb-statuscheck-v1?_onlineuser_id={userId}',
    },
    VALIDATE_EMAIL: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.CHECK_EMAIL,
    },

};

/** PROCESS_ROUTES */
export const PROCESS_ROUTES: Array<string> = [
    API_URLS.PROCESS.STEP,
    API_URLS.PROCESS.START,
];
