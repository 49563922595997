import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserXhr, HttpModule, ResponseOptions, XHRBackend, XSRFStrategy } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationService, AuthModule, CoreAuthenticationService, CoreConfigurationsLoaderService, CoreModule, CustomErrorHandlerService, Helper, PluginsModule, RoutesUtilsService, SpinnerService } from '@vfde-fe/ng-modules';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_PROVIDERS } from './app.providers';
import { SharedModule } from './common/shared.module';
import { ConfigLoaderService } from './common/utils/config-loader.service';
import { CustomTranslateLoader } from './common/utils/custom-translate-loader.component';
import { HttpInterceptor } from './common/utils/http-interceptor.service';
import { ProcessService } from './common/utils/process.service';
import { ValidationService } from './common/utils/validation.service';

/** httpFactory */
export function httpFactory (browserXHR: BrowserXhr, requestOptions: ResponseOptions, xsrfStrategy: XSRFStrategy, router: Router, processService: ProcessService, validationService: ValidationService, customErrorHandlerService: CustomErrorHandlerService, spinnerService: SpinnerService, authenticationService: CoreAuthenticationService, routesUtilsService: RoutesUtilsService, helper: Helper) {
    return new HttpInterceptor(browserXHR, requestOptions, xsrfStrategy, router, processService, validationService, customErrorHandlerService, spinnerService, authenticationService, routesUtilsService, helper);
}

/** createTranslateLoader */
export function createTranslateLoader (http: HttpClient, config: ConfigLoaderService) {
    return new CustomTranslateLoader(http, config, environment.configPath + 'i18n/', '.json');
}

/** configLoaderFactory */
export function configLoaderFactory (config: ConfigLoaderService) {
    return () => config.load();
}

/** AppModule */
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AuthModule.forRoot({
            provide: CoreAuthenticationService,
            useClass: AuthenticationService,
        }),
        CoreModule.forRoot(
            {
                provide: CoreConfigurationsLoaderService,
                useClass: ConfigLoaderService,
            },
        ),
        PluginsModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpModule,
        HttpClientModule,
        AppRoutingModule,
        NgIdleKeepaliveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, CoreConfigurationsLoaderService],
            },
        }),
    ],
    providers: [
        APP_PROVIDERS,
        {
            provide: APP_INITIALIZER,
            useFactory: configLoaderFactory,
            deps: [CoreConfigurationsLoaderService],
            multi: true,
        },
        {
            provide: XHRBackend,
            useFactory: httpFactory,
            deps: [BrowserXhr, ResponseOptions, XSRFStrategy, Router, ProcessService, ValidationService, CustomErrorHandlerService, SpinnerService, CoreAuthenticationService, RoutesUtilsService, Helper],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
