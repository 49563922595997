import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACCESS_LEVELS_LIST, CONTRACT_TYPES_LIST } from '../../constants/Defines';
import { TranslateService } from '@ngx-translate/core';

/** UnauthorizedComponent */
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css'],
})
export class UnauthorizedComponent implements OnInit {
  /** to hold the notifaction details to bindind in the notification */
  public notificationDetails: any = {};
  /** to hold the contractType from the querystring */
  public contractType: string;
  /** to hold the accessLevel from the querystring */
  public accessLevel: string;
  /** to be true if the contractType was undefined or was defined but not included within the UNAUTHORIZED_CONTRACT_TYPES */
  public isGeneral: boolean;

  /** pageTitle */
  public pageTitle: string;
  /** List of the possible unauthorized contract types */
  public UNAUTHORIZED_CONTRACT_TYPES = [
    'peter',
    'bart',
    'emma',
  ];

  constructor (private route: ActivatedRoute, private ts: TranslateService) { }
  /** ngOnInit */
  public ngOnInit () {
    this.route.queryParams
      .subscribe(params => {
        this.contractType = params.contractType;
        this.accessLevel = params.accessLevel;
        this.pageTitle = params.pageTitle;
        // check if general
        this.isGeneral = this.contractType ? (this.UNAUTHORIZED_CONTRACT_TYPES.indexOf(this.contractType) > -1 ? false : true) : true;
        this.contractType = this.isGeneral ? 'general' : this.UNAUTHORIZED_CONTRACT_TYPES.find(x => x === this.contractType);
        // check if access level list is given; if given: check if it's from list: if yes find it, else make it contract - if not given: make it contract
        this.accessLevel = this.accessLevel ? ((this.accessLevel === ACCESS_LEVELS_LIST.CONTRACT || this.accessLevel === ACCESS_LEVELS_LIST.SUBSCRIPTION) ? this.accessLevel : 'contract') : 'contract';
        this.notificationDetails = {
          headline: this.ts.instant('unauthorized.' + this.contractType + '.' + (this.isGeneral ? (this.accessLevel + '.') : '') + 'std-headline'),
          text: this.ts.instant('unauthorized.' + this.contractType + '.' + (this.isGeneral ? (this.accessLevel + '.') : '') + 'text-body'),
          class: 'error',
          iconClass: 'i-red',
          icon: 'i-group-error-circle-sml',
          links: this.contractType === CONTRACT_TYPES_LIST.PETER ? [{
            title: this.ts.instant('unauthorized.' + this.contractType + '.link.std-headline'),
            url: this.ts.instant('unauthorized.' + this.contractType + '.link.link-href'),
            target: this.ts.instant('unauthorized.' + this.contractType + '.link.opt-target'),
          }] : [],
        };

      });
  }

}
