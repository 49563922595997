/**
 * Process
 */
export class Process {
    /** actionType */
    public actionType: string;
    /** processId */
    public processId: string;
    /** parameters */
    public parameters: Object;
    /** stepName */
    public stepName: string;

    constructor (actionType: string,
        processId: string,
        stepName?: string,
        parameters?: Object,
    ) {
        this.actionType = actionType;
        this.processId = processId;
        this.parameters = parameters;
        this.stepName = stepName;

    }
}
