import { LOG_LEVELS } from '../app/common/constants/Defines';

/**
 * The file contents for the current environment will overwrite these during build.
 * The build system defaults to the dev environment which uses `environment.ts`, but if you do
 * `ng build --env=prod` then `environment.prod.ts` will be used instead.
 * The list of which env maps to which file can be found in `angular-cli.json`.
 */
export const environment = {
  production: true,
  stubMode: false,
  baseUrlMint: '/mint',
  baseUrlNil: '/api', // only if we need a NIL Service, e.g. UserData_GET,
  OTDSBaseUrl: '/meinvodafone/services/api',
  oneloginBaseUrl: '/meinvodafone/account',
  configPath: '/simplicity/apps/onelogin/js/config/',
  simplicityAssets: '/simplicity/assets/',
  svgIcons: '/simplicity/svgdefs.svg',
  featuresConfigPath: '/simplicity/apps/onelogin/js/config/features.json',
  logLevel: LOG_LEVELS.LOG,
  cmsConfigPath: '/config/onelogin/',
  cmsErrorMessagesPath: '/api-static/errormessages/',
  gmchatServiceUrl: '/gmchat',
  cmsCommonWording: '/config/deWording-core.json',

};
