import {
  XSRFStrategy, BrowserXhr,
  ResponseOptions, XHRBackend, Request, XHRConnection, Response,
} from '@angular/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ValidationService } from './validation.service';
import { ProcessService } from './process.service';
import { Injector, Injectable } from '@angular/core';

import * as pagesConfig from '../../../config/pages-config';
import { AUTHENTICATION_ERROR_CODE, AUTHENTICATION_WHITELIST, AUTHORIZATION_ERROR_CODE, ONELOGIN_URL } from './../../common/constants/Defines';
import { CustomErrorHandlerService } from '@vfde-fe/ng-modules';
import { environment } from '../../../environments/environment';
import { SpinnerLoader } from '../../models/spinner-loader.model';
import { SpinnerService } from '@vfde-fe/ng-modules';
import { API_URLS } from 'src/app/common/constants/routes-config';
import { CoreAuthenticationService } from '@vfde-fe/ng-modules';
import { RoutesUtilsService } from '@vfde-fe/ng-modules';
import { Helper } from '@vfde-fe/ng-modules';
import { HEADER_CONTEXT_WHITE_LIST } from '@vfde-fe/ng-modules';

/** HttpInterceptor */
@Injectable()
export class HttpInterceptor extends XHRBackend {

  private pagesConfig: any;
  constructor (
    _browserXHR: BrowserXhr,
    _baseResponseOptions: ResponseOptions,
    _xsrfStrategy: XSRFStrategy,
    private router: Router,
    private processService: ProcessService,
    private validationService: ValidationService,
    private customErrorHandlerService: CustomErrorHandlerService,
    private spinnerService: SpinnerService,
    private authenticationService: CoreAuthenticationService,
    private routesUtilsService: RoutesUtilsService,
    private helper: Helper) {
    super(_browserXHR, _baseResponseOptions, _xsrfStrategy);
    this.pagesConfig = pagesConfig.config;
  }

  /** createConnection */
  public createConnection (request: Request): XHRConnection {

    if (request.url.indexOf(environment.baseUrlNil) !== -1) {

      request.headers.append('x-vf-api', (new Date().valueOf()).toString());
      request.headers.append('x-vf-clientID', 'MyVFWeb');
    }
    if (HEADER_CONTEXT_WHITE_LIST.CONTEXT.indexOf(this.helper.getContext()) > -1
      && this.helper.detectIsUrlInWhiteList(request.url, HEADER_CONTEXT_WHITE_LIST.URLS)) {
      request.headers.append('X-USE-CONTEXT', this.helper.getContext());

    }

    const disableSpinner = request.headers.get('disableSpinner') === 'true';
    const loader: SpinnerLoader = !disableSpinner ? this.spinnerService.startLoader() : null;
    const connection: XHRConnection = super.createConnection(request);

    // Check if url is one of the defined process urls to apply generic routing
    connection.response = connection.response.pipe(
      map(res => {
        if (loader) {
          this.spinnerService.endLoader(loader, true);
        }
        if (this.processService.checkIfProcessUri(request.url)) {
          // Successful Response;
          return this.processSuccessResponse(res);
        }

        return res;
      }),
      catchError((error) => {
        if (loader) {
          this.spinnerService.endLoader(loader, false);
        }

        return this.processErrorResponse(error);
      }),
    );

    return connection;
  }
  /** processSuccessResponse */
  public processSuccessResponse (response: any) {
    const result = response.json();
    if (result.stepName) {
      const route = this.processService.handleGenericRouting(result.stepName);
      if (route) {
        this.validationService.addStepAttributes(result.parameters, result.stepName);
        if (this.processService.navigate) {
          this.router.navigate([route]);

        }

        return response;
      } else {
        console.warn('Step name retrieved does not match to any route');

        return response;
      }
    } else if (result.lastStep) {
      return response;
    } else {
      return throwError('Next step not retrieved from BE');
    }
  }

  /**
   * processErrorResponse to handle generic error on level of application (404,500,401,403,etc..)
   */
  public processErrorResponse (response: Response) {
    let errorCode = null;
    try {

      if (response.url.indexOf(environment.baseUrlNil) !== -1) {
        errorCode = response.json().faultMessage ? response.json().faultMessage.code : null;
      } else {
        errorCode = response.json().operationError ? response.json().operationError.code : null;
      }
    } catch (e) {
      // console.log(e);
      // do nothing and not to stop code sequence in case response in not in JSON format
      return throwError(response);
    }
    if (response.url.indexOf(environment.cmsConfigPath) > -1 || response.url.indexOf(environment.cmsErrorMessagesPath) > -1 || response.url.indexOf(environment.cmsCommonWording) > -1) {
      return throwError(response);
    }
    switch (response.status) {
      case 401:
        // redirect to login page
        let foundWhiteList = false;
        if (errorCode === AUTHENTICATION_ERROR_CODE.MINT_AUTHENTICATION_REQUIRED) {
          AUTHENTICATION_WHITELIST.forEach((item) => {
            if (this.router.url.indexOf(this.pagesConfig[item].route) > -1) {
              foundWhiteList = true;
            }
          });
          if (foundWhiteList) {
            return throwError(response);
          } else {
            this.authenticationService.logout();
            this.router.navigate([this.pagesConfig.login.route]);

            return throwError(response);
          }
        } else if (errorCode === AUTHENTICATION_ERROR_CODE.NIL_AUTHENTICATION_REQUIRED) {

          window.location.href = API_URLS.LOGOUT.LOGOUT + '?targetURL=/' + ONELOGIN_URL + pagesConfig.config.login.route + '?targetURL=' + encodeURIComponent(encodeURIComponent(window.location.href));

          return throwError(response);
        }

        return throwError(response);
      //   case 403:
      //     if(errorCode === AUTHORIZATION_ERROR_CODE.SECURITY_CHALLENGE_FAILED ){
      //         let queryParam:any = {
      //             accessLevel: this.processService.getStepUsingName(response.url).access.accessLevel
      //         };
      //         if(this.authenticationService.isAuthenticated()){
      //             queryParam.contractType = this.authenticationService.getCurrentUserModel().getActor();
      //         }
      //         console.log('QueryParam (403):',queryParam);
      //         this.router.navigate([pagesConfig.config.unauthorized.name], {
      //             queryParams: queryParam
      //         });
      //         return;
      //     } else {
      //         return Observable.throw(response);
      //     }
      default:
        const currentModule = this.processService.getCurrentModule() !== '' ? this.processService.getCurrentModule() : undefined;
        const currentUsecase = this.routesUtilsService.getCurrentUsecase() !== '' ? this.routesUtilsService.getCurrentUsecase() : undefined;

        if (!errorCode) {
          this.customErrorHandlerService.getErrorDescription(currentModule, undefined, response.status.toString(), true, true, false, currentUsecase);

          return throwError(response);
        } else {
          this.customErrorHandlerService.getErrorDescription(currentModule, errorCode, response.status.toString(), true, true, false, currentUsecase);

          return throwError(response);
        }
    }
  }
}
