var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WORDING_FILES } from '../constants/Defines';
/** CustomTranslateLoader */
var CustomTranslateLoader = /** @class */ (function (_super) {
    __extends(CustomTranslateLoader, _super);
    /**
     * constructor
     * @param http
     * @param configLoaderService
     * @param prefix
     * @param suffix
     */
    function CustomTranslateLoader(http, configLoaderService, prefix, suffix) {
        var _this = _super.call(this, http, prefix, suffix) || this;
        _this.customHttp = http;
        _this.config = configLoaderService;
        return _this;
    }
    /**
     * Get Translation based on lang
     */
    CustomTranslateLoader.prototype.getTranslation = function (lang) {
        if (lang === WORDING_FILES.defaultWording) {
            return this.config.getDefaultGermanWordingFile();
        }
        else if (lang === WORDING_FILES.deWording) {
            return this.config.getDeWordingFile();
        }
    };
    return CustomTranslateLoader;
}(TranslateHttpLoader));
export { CustomTranslateLoader };
