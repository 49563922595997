import { Injectable, Injector } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { Helper, Route } from '@vfde-fe/ng-modules';
import { find } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Process } from '../../models/process.model';
import { SIMPLICITY_ENV } from '../constants/Defines';
import { API_URLS, PROCESS_ROUTES } from '../constants/routes-config';
import { RoutesService } from '../utils/routes.service';

/** ProcessService */
@Injectable()
export class ProcessService {

  /** _currentModule */
  public _currentModule: string = '';
  /** _currentUsecase */
  public _currentUsecase: string = '';
  /** _currentStep */
  public _currentStep: string = '';
  /** _processId */
  public _processId = '';
  /** http */
  public http: Http;
  /** conf */
  public conf: any;
  /** navigate */
  public navigate: boolean;

  constructor (private routesService: RoutesService, private injector: Injector, private helperService: Helper) {
    this.conf = this.routesService.getPagesConfig();
  }

  /** Process Start */
  public startProcess (processStartKey: string, navigate: boolean = true): Observable<any> {
    this.navigate = navigate;
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    this.http = this.injector.get(Http);

    return this.http.post(API_URLS.PROCESS.START + processStartKey,
      {}, options).pipe(
        map((response) => {
          const data = response.json();
          this._processId = data.processId;

          return response;
        }));
  }

  /**
   * Process Step
   * @param requestBody
   * @param errorCallBack
   * @param headersObj
   * @param that
   * @param navigate
   */
  public submitProcessStep (requestBody, errorCallBack, headersObj?: Headers, that?, navigate: boolean = true) {
    this.navigate = navigate;
    const actionType: string = 'com.uxpsystems.mint.framework.bpm.process.ManualInputStepAction';

    const requestPayload: Process = new Process(actionType, this._processId, this.getCurrentStep());
    requestPayload.parameters = requestBody || {};

    const headers = headersObj || new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });

    const url = this.getProcessStepURL();
    this.http = this.injector.get(Http);

    return this.http.put(url,
      requestPayload, options)
      .pipe(map((response: Response) => {
        const data = response.json();
        this._processId = data.processId;

        return data;
      }),
        catchError((error) => {
          return throwError(errorCallBack(error, that) || error);
        }));
  }

  /**
   * handleGenericRouting
   * @param stepName
   */
  public handleGenericRouting (stepName: string): string {
    this._currentStep = stepName;
    const step = this.getStepUsingStepName(stepName);

    // return this._processRoutesMap[this._currentModule][stepName].route;
    return step ? step.route : null;
  }

  /**
   * checkIfProcessUri
   * @param url
   */
  public checkIfProcessUri (url: string): boolean {
    if ((environment.stubMode || this.helperService.getCookieData('environment') == SIMPLICITY_ENV.dev) && url.indexOf(API_URLS.PROCESS.START) == -1) {
      url = url.replace(/\/process\/(.+)/, '/process/step');
    }

    return !!PROCESS_ROUTES.filter(item => url.indexOf(item) > -1).length;

    // let isProcessUri = PROCESS_ROUTES.indexOf(url) > -1;
    // return isProcessUri;
  }

  /**
   * setCurrentModule
   * @param currentModule
   */
  public setCurrentModule (currentModule: string): void {
    this._currentModule = currentModule;
  }

  /**
   * getCurrentModule
   */
  public getCurrentModule (): string {
    return this._currentModule;
  }
  /** setCurrentUsecase */
  public setCurrentUsecase (currentUsecase: string): void {
    this._currentUsecase = currentUsecase;
  }

  /** getCurrentUsecase */
  public getCurrentUsecase (): string {
    return this._currentUsecase;
  }
  /** setCurrentStep */
  public setCurrentStep (currentStep: string): void {
    this._currentStep = currentStep;
  }

  /** getCurrentStep */
  public getCurrentStep (): string {
    return this._currentStep;
  }

  /** setProcessId */
  public setProcessId (processId: string): void {
    this._processId = processId;
  }

  /** getProcessId */
  public getProcessId (): string {
    return this._processId;
  }

  /** getStepUsingName */
  public getStepUsingName (name: string, moduleName?: string): Route {
    return this.conf[moduleName || this._currentModule][name];
  }

  /**
   * getStepUsingStepName
   * @param stepName
   * @param moduleName
   */
  public getStepUsingStepName (stepName: string, moduleName?: string): Route {
    const step = find(this.conf[moduleName || this._currentModule], function (item: any) {
      return item.stepName === stepName;
    });

    return step;
  }

  /**
   * search for step by step's url and return the full step object
   * @param stepURL - search by step url
   * @param moduleName - determine which module will be searched in
   */
  public getStepUsingStepURL (stepURL: string, moduleName?: string): Route {
    const step = find(this.conf[moduleName || this._currentModule], (item: Route) => item.route === stepURL);

    return step;
  }

  /**
   * for dev routing
   */
  public getProcessStepURL (): string {
    if (environment.stubMode || this.helperService.getCookieData('environment') == SIMPLICITY_ENV.dev) {
      const url = API_URLS.PROCESS.STEP.replace(/step/i, this._currentStep);

      return url;
    } else {
      return API_URLS.PROCESS.STEP;
    }
  }

}
