/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauthorized.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@vfde-fe/ng-modules/vfde-fe-ng-modules.ngfactory";
import * as i3 from "@vfde-fe/ng-modules";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./unauthorized.component";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
function View_UnauthorizedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "transaction-header", [], null, null, null, i2.View_TransactionHeaderComponent_0, i2.RenderType_TransactionHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.TransactionHeaderComponent, [i4.Router, i3.RoutesUtilsService, i3.CommonCacheService, i3.UserCacheService, i3.SimCacheService, i3.UserService, i5.TranslateService, i4.ActivatedRoute, i6.LocationStrategy], { defaultTitleKey: [0, "defaultTitleKey"], accessLevel: [1, "accessLevel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; var currVal_1 = _co.accessLevel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnauthorizedComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "standard-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(4, 901120, null, 0, i3.NotificationComponent, [i4.Router, i5.TranslateService, i3.RoutesUtilsService, i6.PlatformLocation, i3.Helper], { notificationDetails: [0, "notificationDetails"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accessLevel; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.notificationDetails; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 114688, null, 0, i7.UnauthorizedComponent, [i4.ActivatedRoute, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("app-unauthorized", i7.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
