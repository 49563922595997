import { Injectable, Injector } from '@angular/core';
import { Http } from '@angular/http';
import { CoreConfigurationsLoader, regex } from '@vfde-fe/ng-modules';
import { of } from 'rxjs';
import { config } from '../../../config/pages-config';
import { environment } from '../../../environments/environment';

/** ConfigLoaderService */
@Injectable()
export class ConfigLoaderService extends CoreConfigurationsLoader {

    /** http */
    public http: Http;
    /** errors */
    public errors: any;
    /** features */
    public features: any;
    /** defaultGermanWordingfile */
    public defaultGermanWordingfile: any;
    /** deWordingfile */
    public deWordingfile: any;
    /** commonErrors */
    public commonErrors: any;
    /** commonWording */
    public commonWording: any;
    /** coreDefaultGermanWording */
    public coreDefaultGermanWording: any;
    /** context */
    public context: string;

    /**
     * constructor
     * @param injector
     */
    public constructor (private injector: Injector) {
        super();
        const currentUrl = window.location.href;
        const availableContext = currentUrl.match(regex.context);
        this.context = availableContext ? availableContext[1] : '';
    }

    /**
     * loads all configuration files at once by trying getting it from cms first then it gets the local one if it failed
     * the configutation files includes the following:
     * error-message - the error messages file
     * common.error - the common error messages for the general error
     * defaultWording - the local default wording file
     * deWording - the german wording file on CMS
     *             it first checks if context available and tries getting it,
     *             if it failed to load the context-dewording file for any reason it tries getting the general deWording
     */
    public load (): Promise<any> {

        const errorsConfig = new Promise((resolve, reject) => {
            setTimeout(() => {
                const http = this.injector.get(Http);
                http.get(environment.cmsConfigPath + 'error-messages.json').subscribe(response => {
                    try {

                        this.errors = response.json();

                        resolve(this.errors);
                    } catch (error) {

                        console.warn('not valid json for error-messages.json file');

                        http.get(environment.configPath + 'error-messages.json').subscribe(response => {
                            this.errors = response.json();
                            resolve(this.errors);
                        });
                    }
                }, error => {
                    http.get(environment.configPath + 'error-messages.json').subscribe(response => {
                        this.errors = response.json();
                        resolve(this.errors);
                    });
                });
            });
        });

        const commonErrorsConfig = new Promise((resolve, reject) => {
            setTimeout(() => {
                const http = this.injector.get(Http);
                http.get(environment.cmsErrorMessagesPath + 'common.error.js').subscribe(response => {

                    try {

                        this.commonErrors = response.json();

                        resolve(this.commonErrors);
                    } catch (error) {

                        console.warn('not valid json for common.error.js file');

                        http.get(environment.configPath + 'common.error.json').subscribe(response => {
                            this.commonErrors = response.json();
                            resolve(this.commonErrors);
                        });
                    }
                }, error => {
                    http.get(environment.configPath + 'common.error.json').subscribe(response => {
                        this.commonErrors = response.json();
                        resolve(this.commonErrors);
                    });
                });
            });
        });

        const defaultGermanWording = new Promise((resolve, reject) => {
            setTimeout(() => {
                const http = this.injector.get(Http);
                http.get(environment.configPath + 'i18n/defaultWording.json').subscribe(response => {
                    this.defaultGermanWordingfile = response.json();
                    resolve(this.defaultGermanWordingfile);
                });
            });
        });

        const deWording = new Promise((resolve, reject) => {
            setTimeout(() => {
                const http = this.injector.get(Http);
                if (this.context) {
                    http.get(environment.cmsConfigPath + 'i18n/deWording-' + this.context + '.json').subscribe(response => {

                        try {

                            this.deWordingfile = response.json();
                            resolve(this.deWordingfile);
                        } catch (error) {
                            console.warn('not valid json for deWording' + this.context + '.json file');
                        }
                    }, (err) => {
                        http.get(environment.cmsConfigPath + 'i18n/deWording.json').subscribe(response => {

                            try {
                                this.deWordingfile = response.json();
                                resolve(this.deWordingfile);
                            } catch (error) {

                                console.warn('not valid json for deWording.json file');
                            }
                        }, (err) => {
                            resolve(null);
                        });
                    });
                }
                else {
                    http.get(environment.cmsConfigPath + 'i18n/deWording.json').subscribe(response => {

                        try {
                            this.deWordingfile = response.json();
                            resolve(this.deWordingfile);
                        } catch (error) {
                            console.warn('not valid json for deWording.json file');
                        }
                    }, (err) => {
                        resolve(null);
                    });
                }
            });
        });

        const coreDefaultGermanWording = new Promise((resolve, reject) => {
            setTimeout(() => {
                const http = this.injector.get(Http);
                http.get(environment.configPath + 'i18n/defaultWording-core.json').subscribe(response => {
                    this.coreDefaultGermanWording = response.json();
                    resolve(this.coreDefaultGermanWording);
                }, (error) => {
                    resolve();
                });
            });
        });

        const coreWording = new Promise((resolve, reject) => {

            setTimeout(() => {
                const http = this.injector.get(Http);
                http.get(environment.cmsCommonWording).subscribe(response => {
                    try {

                        this.commonWording = response.json();

                        resolve(this.commonWording);
                    } catch (error) {

                        console.warn('not valid json for core-deWording.json file');
                    }
                }, (err) => {
                    resolve(null);
                });
            });
        });

        return Promise.all([errorsConfig, commonErrorsConfig, defaultGermanWording, deWording, coreWording, coreDefaultGermanWording]);

    }

    /** loadPagesRoutes */
    public loadPagesRoutes (): object | object[] {
        return config;
    }

    /** getcommonErrorsConfig */
    public getcommonErrorsConfig () {
        return this.commonErrors;
    }

    /** getErrorsConfig */
    public getErrorsConfig () {
        return this.errors;
    }
    /** getDefaultGermanWordingFile */
    public getDefaultGermanWordingFile () {
        return of(Object.assign(this.defaultGermanWordingfile, this.coreDefaultGermanWording));
    }
    /** getDeWordingFile */
    public getDeWordingFile () {
        return of(Object.assign(this.deWordingfile || {}, this.commonWording));
    }
    /** setCoreConfigRoute */
    public setCoreConfigRoute (): void {
        this.generalError = this.loadPagesRoutes()['generalError'].route;
        this.unauthorized = this.loadPagesRoutes()['unauthorized'].route;

    }
}
