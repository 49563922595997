import { HttpClient } from '@angular/common/http';
import { BrowserXhr, ResponseOptions, XSRFStrategy } from '@angular/http';
import { Router } from '@angular/router';
import { CoreAuthenticationService, CustomErrorHandlerService, Helper, RoutesUtilsService, SpinnerService } from '@vfde-fe/ng-modules';
import { environment } from '../environments/environment';
import { ConfigLoaderService } from './common/utils/config-loader.service';
import { CustomTranslateLoader } from './common/utils/custom-translate-loader.component';
import { HttpInterceptor } from './common/utils/http-interceptor.service';
import { ProcessService } from './common/utils/process.service';
import { ValidationService } from './common/utils/validation.service';
/** httpFactory */
export function httpFactory(browserXHR, requestOptions, xsrfStrategy, router, processService, validationService, customErrorHandlerService, spinnerService, authenticationService, routesUtilsService, helper) {
    return new HttpInterceptor(browserXHR, requestOptions, xsrfStrategy, router, processService, validationService, customErrorHandlerService, spinnerService, authenticationService, routesUtilsService, helper);
}
/** createTranslateLoader */
export function createTranslateLoader(http, config) {
    return new CustomTranslateLoader(http, config, environment.configPath + 'i18n/', '.json');
}
/** configLoaderFactory */
export function configLoaderFactory(config) {
    return function () { return config.load(); };
}
var ɵ0 = (createTranslateLoader);
/** AppModule */
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
