import { RoutesService } from './common/utils/routes.service';
import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageScrollConfig } from 'ngx-page-scroll';
import { IdleService } from './common/utils/idle.service';
import { Location } from '@angular/common';
import { DATA_LOSS_WHITELIST } from './common/constants/Defines';
import { TrackingInterceptor } from '@vfde-fe/ng-modules';
import { SpinnerService } from '@vfde-fe/ng-modules';
import { AccordionInterceptor } from '@vfde-fe/ng-modules';
import { SpinnerStatus } from '@vfde-fe/ng-modules';
import { ConfirmDataLossService } from '@vfde-fe/ng-modules';
import { Spinner } from '@vfde-fe/ng-modules';

/** AppComponent */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  /** boolean value to indicate if spinner loader is shows up or not */
  public generalLoader = false;
  /** spinner */
  public spinner: Spinner;
  constructor (
      translate: TranslateService,
      spinnerService: SpinnerService,
      idleService: IdleService,
      private routesService: RoutesService,
      private trackingInterceptor: TrackingInterceptor,
      private accordionInterceptor: AccordionInterceptor,
      private location: Location,
      private confirmDataLossService: ConfirmDataLossService,
    ) {
    // if application is loaded with a H&S accordion opened. this removes the hash to close the accordion
    if (location.path(true).indexOf('#') !== -1) {
                location.go(location.path(true).split('#')[0]);
    }
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('defaultWording');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('deWording');
    // execute general loader callback with this to use generalLoader property
    spinnerService.generalLoaderCallback(this.switchGeneralLoaderSpinner, this);

    /** page scrolling configuration */
    PageScrollConfig.defaultScrollOffset = 10;

    // add whitelist to data loss service
    confirmDataLossService.whitelistUrls = DATA_LOSS_WHITELIST;

    // Start Idle service
    idleService.start();

    // Start accordion interceptor
    accordionInterceptor.init();

    // Start tracking Interceptor
    trackingInterceptor.init();
  }

  /**
   * switch the boolean indicator to load the spinner or show the router outlet
   */
  private switchGeneralLoaderSpinner (that: any, spinnerStatus: SpinnerStatus, spinner?: Spinner) {
    that.spinner = spinner;
    that.generalLoader = (spinnerStatus === SpinnerStatus.START) ? true : false;
 }

}
