/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@vfde-fe/ng-modules/vfde-fe-ng-modules.ngfactory";
import * as i2 from "@vfde-fe/ng-modules";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./common/utils/idle.service";
import * as i8 from "./common/utils/routes.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "spinner", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 114688, null, 0, i2.SpinnerComponent, [], { spinner: [0, "spinner"] }, null), i0.ɵdid(2, 606208, null, 0, i2.SpinnerDirective, [i2.SpinnerService, i2.AccordionService], { spinner: [0, "spinner"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinner; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.spinner; _ck(_v, 2, 0, currVal_1); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i3.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { "display": 0 }), i0.ɵpod(5, { "display": 0 }), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.generalLoader; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.generalLoader && (!_co.spinner || !_co.spinner.isDim)) ? _ck(_v, 4, 0, "none") : _ck(_v, 5, 0, "block")); _ck(_v, 3, 0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i5.AppComponent, [i6.TranslateService, i2.SpinnerService, i7.IdleService, i8.RoutesService, i2.TrackingInterceptor, i2.AccordionInterceptor, i3.Location, i2.ConfirmDataLossService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
