/* eslint-disable */

export var config = {
    home: {
        name: 'home',
        route: '/home'
    },
    registration: {
        name: 'registrierung',
        route: '/registrierung',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.registration',
        pageType: 'oneTimeTransaction',
        chooseRegistrationType: {
            name: 'registrierungsart',
            route: '/registrierung/registrierungsart',
            stepName: 'choose-registration-type',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify user type'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 1,
                    stepName: 'identify user type',
                    status: ['start']
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        chooseContractType: {
            name: 'vertragstyp',
            route: '/registrierung/vertragstyp',
            stepName: 'choose-contract-type',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract'],
                    siteArea: 'meinvodafone',
                    module : 'register user',
                    step : 2,
                    stepName : 'choose contract type',
                    status : []
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },

        submitPersonalData: {
            name: 'persoenliche_daten',
            route: '/registrierung/persoenliche_daten',
            stepName: 'submit-personal-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['register user', 'edit userdata', 'enter name'],
                    module: 'register user',
                    step : 6,
                    stepName : 'personal data',
                    status : []
                }
            }
        },
        submitMobileIdentifier: {
            name: 'mobilvertrag',
            route: '/registrierung/mobilvertrag',
            stepName: 'submit-mobile-identifier',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['register user', 'identify contract', 'enter mobile contract'],
                    module: 'register user',
                    step : 3,
                    stepName : 'enter mobile credentials',
                    status : []
                }
            }
        },
        submitCableCredentials: {
            name: 'aktivierungscode',
            route: '/registrierung/aktivierungscode',
            stepName: 'submit-cable-credentials',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter vkd contract'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 3,
                    stepName : 'enter vkd credentials',
                    status : []
                }
            }
        },
        submitDslCredentials: {
            name: 'onlineregcode',
            route: '/registrierung/onlineregcode',
            stepName: 'submit-dsl-registration-code',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter dsl contract'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 3,
                    stepName : 'enter dsl credentials',
                    status : []
                }
            }
        },
        submitBanCredentials: {
            name: 'vertragsdaten',
            route: '/registrierung/vertragsdaten',
            stepName: 'submit-ban-credentials'
        },
        submitSubscriberCredentials: {
            name: 'teilnehmerdaten',
            route: '/registrierung/teilnehmerdaten',
            stepName: 'submit-subscriber-credentials'
        },
        temporaryAccountDetected: {
            name: 'autoregistriert',
            route: '/registrierung/autoregistriert',
            stepName: 'temp-account-detected',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter mobile contract', 'temp account detected'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 4,
                    stepName : 'temp account detected',
                    status : []
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        submitContactData: {
            name: 'kontaktdaten',
            route: '/registrierung/kontaktdaten',
            stepName: 'submit-contact-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'enter contact data'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 7,
                    stepName : 'contact data',
                    status : []
                }
            }
        },
        submitUserData: {
            name: 'accountdaten',
            route: '/registrierung/accountdaten',
            stepName: 'submit-user-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'enter username + pw'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 8,
                    stepName : 'user data',
                    status : []
                }
            }
        },
        reviewData: {
            name: 'uebersicht',
            route: '/registrierung/uebersicht',
            stepName: 'review-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'review userdata'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 9,
                    stepName : 'review account data',
                    status : []
                }
            }
        },
        accountConfirmation: {
            name: 'bestaetigung',
            route: '/registrierung/bestaetigung',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'confirmation'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 10,
                    stepName : 'confirmation account setup',
                    status : []
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false
            }
        },
        renameContract: {
            name: 'vertrag_umbenennen',
            route: '/registrierung/vertrag_umbenennen',
            stepName: 'enter-mobile-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    siteStructure: ['register user', 'edit mbo data', '{{siteStructureRenameContract}}'],
                    step: 5,
                    stepName : '{{stepNameRenameContractRegistration}}',
                    status : []
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.skip.std-headline',
                navigationLink: '/login'
            }
        },
        addKdDslContractName: {
            name: 'vertragsname',
            route: '/registrierung/vertragsname',
            stepName: 'enter-dsl-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit mbo data', 'edit dsl or vkd'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 11,
                    stepName : 'edit dsl or vkd',
                    status : []
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.skip.std-headline',
                navigationLink: '/login'
            }
        },
        addCableContractName: {
            name: 'cablename',
            route: '/registrierung/vertragsname',
            stepName: 'enter-cable-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit mbo data', 'edit dsl or vkd'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 5,
                    stepName : 'edit dsl or vkd',
                    status : []
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.skip.std-headline',
                navigationLink: '/login'
            }
        },
        renameContractConfirmation: {
            name: 'vertraghinzugefuegt',
            route: '/registrierung/vertraghinzugefuegt',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'registration',
                    step: 12,
                    stepName: 'confirmation',
                    status: ['end', 'success'],
                    siteStructure: ['register user', 'edit mbo data', 'confirmation'],
                    siteArea: 'meinvodafone',
                    outcome: '{{outcomeRenameContractConfirmation}}',
                    type: 'myvodafone:transaction'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.goToMeinVodafone.std-headline'
            }
        },
        validateEmail: {
            name: 'validate_email',
            route: '/registrierung/validate_email',
            stepName: 'validate-email'
        }
    },
    addContract: {
        name: 'erweitern',
        route: '/erweitern',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.addContract',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/verwaltung/ueberblick'
        },
        access: {
            accessLevel: 'account'
        },
        notifyBeforeUnload: true,
        addMobileContract: {
            name: 'vertrag_mobil',
            route: '/erweitern/vertrag_mobil',
            stepName: 'enter-mobile-data',
            titleKey: 'addContractModule.addMobileContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'choose contract type', 'enter mobile contract'],
                    module: 'add contract',
                    step : 2,
                    stepName: 'add mobile contract' ,
                    status : []
                },
                transactionState: 'progressing'
            }
        },
        enterMobileName: {
            name: 'vertrag_mobil_umbenennen',
            route: '/erweitern/vertrag_mobil_umbenennen',
            stepName: 'enter-mobile-name',
            titleKey: 'addContractModule.enterMobileName.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'name contract'],
                    module: 'add contract',
                    step: 3,
                    stepName: 'name contract',
                    status : []
                },
                transactionState: 'progressing'
            }
        },
        contractTypeChoice: {
            name: 'vertragstyp',
            route: '/erweitern/vertragstyp',
            stepName: 'contract-type-choice',
            titleKey: 'addContractModule.contractTypeChoice.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'choose contract type'],
                    step: 1,
                    stepName: 'choose contract type',
                    status: ['start'],
                    context: '{{contextAddContract}}',
                    type: 'myvodafone:transaction',
                    module: 'add contract'
                },
                transactionState: 'start'
            }
        },
        addDslContract: {
            name: 'vertrag_dsl',
            route: '/erweitern/vertrag_dsl',
            stepName: 'enter-dsl-data',
            titleKey: 'addContractModule.addDslContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'add contract',
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'choose contract type', 'enter dsl contract'],
                    step: 2,
                    stepName: 'add dsl contract',
                    status : []
                },
                transactionState: 'progressing'
            }
        },
        enterDslName: {
            name: 'vertrag_dsl_umbenennen',
            route: '/erweitern/vertrag_dsl_umbenennen',
            stepName: 'enter-dsl-name',
            titleKey: 'addContractModule.nameDslContract.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'name contract'],
                    module: 'add contract',
                    step: 3,
                    stepName: 'name contract',
                    status: []
                },
                transactionState: 'progressing'
            }
        },
        addContractConfirmation: {
            name: 'vertrag_hinzugefuegt',
            route: '/erweitern/vertrag_hinzugefuegt',
            stepName: 'add-contract-confirmation',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            },
            titleKey: {
                mobile: 'addContractModule.addMobileContract.std-headline',
                dsl: 'addContractModule.addDslContract.std-headline',
                cable: 'addContractModule.addKdContract.std-headline',
                DEFAULT: 'mobile'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'confirmation'],
                    siteArea: 'meinvodafone',
                    module: 'add contract',
                    step: 4,
                    stepName: 'confirmation',
                    status: ['end', 'success']
                },
                transactionState: 'end'
            },
            confirmationPage: true
        },
        addKdContract: {
            name: 'vertrag_kabel',
            route: '/erweitern/vertrag_kabel',
            stepName: 'enter-cable-data',
            titleKey: 'addContractModule.addKdContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'choose contract type', 'enter vkd contract'],
                    module: 'add contract',
                    step: 2 ,
                    stepName: 'add VKD contract',
                    status: []
                }
            }
        },
        nameKdContract: {
            name: 'vertrag_kabel_umbenennen',
            route: '/erweitern/vertrag_kabel_umbenennen',
            stepName: 'enter-cable-name',
            titleKey: 'addContractModule.nameKdContract.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'name contract'],
                    module: 'add contract',
                    step: 3 ,
                    stepName: 'name contract',
                    status : []
                },
                transactionState: 'progressing'
            }
        },
        permissionConfirmation: {
            name: 'einwilligung',
            route: '/erweitern/einwilligung',
            titleKey: 'addContractModule.permissionConfirmation.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'VKD agreement required'],
                    step: 1,
                    module: 'add contract',
                    stepName: 'VKD agreement required',
                    context: '{{contextAddContract}}',
                    status: ['start']
                },
                transactionState: 'end'
            }
        },
        permissionDenaied: {
            name: 'einwilligung_ohne',
            route: '/erweitern/einwilligung_ohne',
            titleKey: 'addContractModule.permissionDenied.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage contracts', 'add contract', 'service blocked'],
                    module: 'add contract',
                    step: 1,
                    context: '{{contextAddContract}}',
                    stepName: 'service blocked',
                    status: ['start']
                }
            }
        },
        permissionGranted: {
            name: 'einwilligung_mit',
            route: '/erweitern/einwilligung_mit',
            titleKey: 'addContractModule.permissionGranted.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'VKD agreement'],
                    module: 'vkd agree data transfer',
                    step: 2,
                    stepName: 'confirm agreement',
                    status: ['end', 'success'],
                    context: '{{contextpermissionGranted}}',
                    type: 'myvodafone:transaction'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/login'
            }
        }
    },
    loginDayOne: {
        name: 'migration',
        route: '/migration',
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.loginDay1',
        pageType: 'oneTimeTransaction',
        confirmMigration: {
            name: 'bestaetigung',
            route: '/migration/bestaetigung',
            stepName: 'confirm-migration',
            tracking: {
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 1,
                    status: ['start']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        vodafoneCustomer: {
            name: 'startmeinvf',
            route: '/migration/startmeinvf',
            stepName: 'confirm-migration',
            titleKey: 'loginDayOne.vodafoneCustomer.std-headline',
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        confirmNoDataTransfer: {
            name: 'einwilligung',
            route: '/migration/einwilligung',
            tracking: {
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 10,
                    stepName: 'migrate w/o data transfer mergeattempt',
                    status: []
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            }
        },
        confirmAccountData: {
            name: 'accountdaten',
            route: '/migration/accountdaten',
            confirmationPage: true,
            stepName: 'confirmation-account-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 12,
                    stepName: 'confirmation',
                    status: ['end', 'success']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                headerKey: 'common.buttons.goToMeinVodafone.std-headline',
                confirmationPage: true
            }
        },
        chooseUserData: {
            titleKey: 'loginDayOne.chooseUserData.std-headline',
            name: 'nutzerdaten',
            route: '/migration/nutzerdaten',
            stepName: 'choose-userdata',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 6,
                    stepName: 'merge accts choose continued acct',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        formSetPassword: {
            titleKey: 'loginDayOneModule.formSetPassword.std-headline',
            name: 'passwort',
            route: '/migration/passwort',
            stepName: 'form-set-password',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 11,
                    stepName: 'set password',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        mergeAccountChooser: {
            name: 'zusammenlegung_auswahl',
            route: '/migration/zusammenlegung_auswahl',
            stepName: 'dialog-merge-account',
            titleKey: 'loginDayOneModule.mergeAccountChooser.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 2,
                    stepName: 'merge accts same username',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        mergeAccount: {
            name: 'zusammenlegung',
            route: '/migration/zusammenlegung',
            stepName: 'merge-account-pw',
            titleKey: 'loginDayOneModule.mergeAccount.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 3,
                    stepName: 'merge accts login 2nd acct',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/migration/zusammenlegung_auswahl'
            }
        },
        changeUsername: {
            name: 'benutzername',
            route: '/migration/benutzername',
            stepName: 'change-username',
            titleKey: 'loginDayOneModule.changeUsernameAndPassword.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 7,
                    stepName: 'change username',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login'
            }
        },
        changeUserConfirmMigration: {
            name: 'zusammenlegung_bestaetigen',
            route: '/migration/zusammenlegung_bestaetigen',
            stepName: 'changeUserConfirmMigration',
            titleKey: 'loginDayOneModule.changeUserConfirmMigration.std-headline',
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        confirmationChangeUsername: {
            name: 'benutzername_bestaetigt',
            route: '/migration/benutzername_bestaetigt',
            stepName: 'confirmationChangeUsername',
            titleKey: 'loginDayOneModule.confirmationChangeUsername.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 9,
                    stepName: 'change username confirmation',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        mergeMigration: {
            name: 'zusammenlegung_migration',
            route: '/migration/zusammenlegung_migration',
            stepName: 'confirm-merge',
            titleKey: 'loginDayOneModule.mergeMigration.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 4,
                    stepName: 'merge accts review',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        mergeUsername: {
            name: 'zusammenlegung_benutzername',
            route: '/migration/zusammenlegung_benutzername',
            stepName: 'change-username'
            // 'titleKey: 'loginDayOne.mergeAccount.standard-headline'
        },
        confirmationMergeDialog: {
            titleKey: 'loginDayOne.confirmationMergeDialog.std-headline',
            name: 'zusammenlegung_entscheidung',
            route: '/migration/zusammenlegung_entscheidung',
            stepName: 'confirmation-merge-dialog',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 5,
                    stepName: 'merge accts confirmation ',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        identifyAccount: {
            name: 'account_identifizieren',
            route: '/migration/account_identifizieren',
            stepName: 'identify-account',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 2,
                    stepName: 'choose additional auth',
                    status: []
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'

            }

        },
        submitAccountCredentialsMobile: {
            name: 'account_identifizieren_mobil',
            route: '/migration/account_identifizieren_mobil',
            stepName: 'submit-account-credentials-mobile',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 3,
                    stepName: 'additional auth mobile',
                    status: []
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/migration/account_identifizieren'
            }

        },
        submitAccountCredentialsCable: {
            name: 'account_identifizieren_kabel',
            route: '/migration/account_identifizieren_kabel',
            stepName: 'submit-account-credentials-cable',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 3,
                    stepName: 'additional auth vks',
                    status: []
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/migration/account_identifizieren'
            }

        },
        submitAccountCredentialsDSL: {
            name: 'account_identifizieren_DSL',
            route: '/migration/account_identifizieren_DSL',
            stepName: 'submit-account-credentials-DSL',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 3,
                    stepName: 'additional auth dsl',
                    status: []
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/migration/account_identifizieren'
            }

        },
        identifyAccountEmail: {
            name: 'account_identifizieren_email',
            route: '/migration/account_identifizieren_email',
            stepName: 'identify-account-Email',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 3,
                    stepName: 'additional auth e-mail',
                    status: []
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/migration/account_identifizieren'
            }
        },
        identifyAccountTan: {
            name: 'account_identifizieren_tan',
            route: '/migration/account_identifizieren_tan',
            stepName: 'identify-account-TAN',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'loginday1'],
                    module: 'login day 1',
                    step: 4,
                    stepName: 'additional auth e-mail 2nd step',
                    status: []
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/migration/account_identifizieren_email'
            }
        },
        validateEmail: {
            name: 'validate_email',
            route: '/migration/validate_email',
            stepName: 'validate-email',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    module: 'login day 1',
                    siteStructure: ['login', 'loginday1', 'force e-mail verification', 'validate email'],
                    siteArea: 'meinvodafone'
                }
            }
        }

    },
    login: {
        name: 'login',
        route: '/login',
        navigation: {
            topLink: false,
            mobileBtn: true
        },
        reset: {
            name: 'zugangsdaten_vergessen',
            route: '/login/zugangsdaten_vergessen',
            titleKey: 'loginModule.resetCredential.std-headline',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'choose credential'],
                    siteArea: 'meinvodafone',
                    module: 'forgotten login data'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login'
            }
        },
        tracking: {
            trackingType: 'page',
            trackingTags: {
                siteStructure: ['login', 'login'],
                module: 'login'
            }
        }
    },
    firstLogin: {
        name: 'firstlogin',
        route: '/firstlogin',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.firstLogin',
        titleKey: 'firstLoginModule.std-headline',
        pageType: 'oneTimeTransaction',
        notifyBeforeUnload: true,
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        enterContactData: {
            name: 'kontaktdaten',
            route: '/firstlogin/kontaktdaten',
            titleKey: 'firstLoginModule.std-headline',
            stepName: 'enter-contact-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'first login', 'enter contact data'],
                    siteArea: 'meinvodafone',
                    module: 'first login',
                    step: 1,
                    stepName: 'personal data',
                    status: ['start'],
                    context: '{{contextEnterContactDataFirstLogin}}'
                }
            }
        },
        enterLoginData: {
            name: 'accountdaten',
            route: '/firstlogin/accountdaten',
            titleKey: 'firstLoginModule.std-headline',
            stepName: 'enter-login-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'first login', 'enter username + pw'],
                    siteArea: 'meinvodafone',
                    module: 'first login',
                    step: 2,
                    stepName: 'user data',
                    status: []
                }
            }
        },
        accountConfirmation: {
            name: 'bestaetigung',
            route: '/firstlogin/bestaetigung',
            titleKey: 'firstLoginModule.std-headline',
            stepName: 'account-confirmation',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'first login', 'confirmation userdata'],
                    siteArea: 'meinvodafone',
                    module: 'first login',
                    step: 3,
                    stepName: 'confirmation',
                    status: ['end', 'success']
                },
                transactionState: 'end'
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.goToMeinVodafone.std-headline'
            }
        },
        validateEmail: {
            name: 'validate_email',
            route: '/firstlogin/validate_email',
            stepName: 'validate-email'
        }

    },
    forgetPassword: {
        name: 'passwort',
        route: '/passwort',
        titleKey: 'forgetPasswordModule.std-headline',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.resetPasswordRequest',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        notifyBeforeUnload: true,
        submitResetPasswordToken: {
            name: 'submitResetPasswordToken',
            route: '/passwort/submitResetPasswordToken',
            stepName: 'submit-reset-password-token'
        },
        changePassword: {
            name: 'passwort_aendern',
            route: '/passwort/passwort_aendern',
            stepName: 'change-password',
            titleKey: 'forgetPasswordModule.changePassword.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data new password',
                    step: 1,
                    siteStructure: ['login', 'forgotten login data', 'set password', 'start'],
                    siteArea: 'meinvodafone',
                    stepName: 'set password',
                    context: '{{contextDynamicValue}}',
                    status: ['start']
                },
                transactionState: 'start'
            }
        },
        changePasswordConfirm: {
            name: 'passwort_bestaetigt',
            route: '/passwort/passwort_bestaetigt',
            titleKey: 'forgetPasswordModule.changePasswordConfirm.std-headline',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data new password',
                    step: 2,
                    siteStructure: ['login', 'forgotten login data', 'set password', 'confimation'],
                    siteArea: 'meinvodafone',
                    stepName: 'confirmation',
                    status: ['end', 'success']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'Zum Login'
            }
        },
        alternativeMethods: {
            name: 'methode',
            route: '/passwort/methode',
            stepName: 'alternative-password-recovery-choice',
            titleKey: 'forgetPasswordModule.validateEmail.std-headline',
            tracking: {
                trackingTags: {
                    module: 'forgotten login data',
                    step: 2,
                    siteStructure: ['login data', 'forgotten login data'],
                    status: [],
                    siteArea: 'meinvodafone'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'

            }
        },
        chooseContract: {
            name: 'vertragsauswahl',
            route: '/passwort/vertragsauswahl',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'choose alternative methode', 'choose contract type'],
                    siteArea: 'meinvodafone'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            }
        },
        validateEmail: {
            name: 'email_bestaetigen',
            route: '/passwort/email_bestaetigen',
            titleKey: 'forgetPasswordModule.validateEmail.std-headline',
            stepName: 'reset-password-init',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data',
                    step: 1,
                    siteStructure: ['login', 'forgotten login data', 'enter user name'],
                    siteArea: 'meinvodafone',
                    stepName: 'enter user name',
                    status: ['start']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login/zugangsdaten_vergessen'
            }
        },
        cableContract: {
            name: 'vertrag_kabel',
            route: '/passwort/vertrag_kabel',
            titleKey: 'forgetPasswordModule.cableContract.std-headline',
            stepName: 'cable-contract',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'choose alternative methode', 'choose contract type', 'edit VKD contract'],
                    siteArea: 'meinvodafone'
                }
            }
        },
        vodafoneContract: {
            name: 'vertrag_mobildsl',
            route: '/passwort/vertrag_mobildsl',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: ['mein vodafone', 'login', 'forgotten login data', 'choose alternative methode', 'choose contract type', 'choose VF contract'],
                    siteArea: 'meinvodafone'
                }
            }
        },
        mobileContract: {
            name: 'mobileContract',
            route: '/passwort/vertrag_mobildsl',
            stepName: 'mobile-contract'
        },
        dslContract: {
            name: 'dslContract',
            route: '/passwort/vertrag_mobildsl',
            stepName: 'dsl-contract'
        },
        securityQuestions: {
            name: 'sicherheitsfragen',
            route: '/passwort/sicherheitsfragen',
            titleKey: 'forgetPasswordModule.securityQuestions.std-headline',
            stepName: 'submit-security-questions',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'choose alternative methode', 'answer security questions'],
                    siteArea: 'meinvodafone'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/passwort/methode'
            }
        },
        recoverNotPossible: {
            name: 'nicht_moeglich',
            route: '/passwort/nicht_moeglich',
            titleKey: 'forgetPasswordModule.recoverNotPossible.std-headline',
            stepName: 'recover-not-possible',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: [
                        'login',
                        'forgotten login data',
                        'choose alternative methode',
                        'choose contract type',
                        'no contract - no pw reset possible'],
                    siteArea: 'meinvodafone'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/passwort/vertragsauswahl'
            },
            fallbackDeeplinking: {
                url: '/meinvodafone/account/passwort/vertragsauswahl',
                disableRefresh: false
            }
        }
    },
    demo: {
        name: 'demo',
        route: '/demo',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.demo',
        stepOne: {
            name: 'stepOne',
            route: '/demo/stepOne',
            stepName: 'demo-step1'
        },
        stepTwo: {
            name: 'stepTwo',
            route: '/demo/stepTwo',
            stepName: 'demo-step2'
        },
        custom: {
            name: 'custom',
            route: '/demo/custom',
            stepName: 'stepCustom'
        },
        errorHandlingSimulation: {
            name: 'errorHandlingSimulation',
            route: '/errorHandlingSimulation'
        },
        deepLinkSingleContractSubscribtion: {
            name: 'deepLinkSingleContractSubscribtion',
            route: '/demo/deepLinkSingleContractSubscribtion'
        },
        kdHeaderDemo: {
            name: 'kdHeader',
            route: '/demo/kdHeader',
            titleKey: 'Page Title'
        },
        access: {
            accessLevel: 'subscription',
            accessList: ['marie', 'kay', 'peter', 'paul', 'anna', 'mark', 'martin']
        }
    },
    unauthorized: {
        name: 'nicht_erlaubt',
        route: '/nicht_erlaubt'
    },
    generalError: {
        name: 'allgemeiner_fehler',
        route: '/allgemeiner_fehler'
    },
    requestActivationCode: {
        name: 'aktivierungscode',
        route: '/aktivierungscode',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
        },
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.customerActivationCodeLetter',
        access: {
            accessLevel: 'account'
        },
        submitCustomerNumber: {
            titleKey: 'requestActivationCodeModule.submitCustomerNumber.std-headline',
            name: 'kundennummer',
            route: '/aktivierungscode/kundennummer',
            stepName: 'submit-customer-number',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['manage account', 'request activation code'],
                    module: 'request activation code',
                    step: 1,
                    stepName: 'enter customer number',
                    status: ['start']
                },
                transactionState: 'start'
            }
        },
        submitConfirmation: {
            titleKey: 'requestActivationCodeModule.submitConfirmation.std-headline',
            name: 'anfordern',
            route: '/aktivierungscode/anfordern',
            stepName: 'submit-confirmation',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['manage account', 'request activation code'],
                    module: 'request activation code',
                    step: 2,
                    stepName: 'confirmation request',
                    status: ['start']
                },
                transactionState: 'start'
            }
        },
        confirmation: {
            titleKey: 'requestActivationCodeModule.confirmation.std-headline',
            name: 'bestaetigung',
            route: '/aktivierungscode/bestaetigung',
            stepName: 'confirmation',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['manage account', 'request activation code'],
                    module: 'request activation code',
                    step: 3,
                    stepName: 'confirmation code sent again',
                    status: ['end', 'success']
                },
                transactionState: 'end'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions',
                mobileBtnLabelKey: 'common.buttons.back.std-headline'
            }
        }
    },
    accountManagement: {
        name: 'verwaltung',
        route: '/verwaltung',
        pageType: 'oneTimeTransaction',
        access: {
            accessLevel: 'account'
        },
        confirmDeleteAccount: {
            name: 'loeschen_bestaetigt',
            route: '/verwaltung/loeschen_bestaetigt',
            titleKey: 'accountManagementModule.confirmDeleteAccount.std-headline',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'delete account',
                    siteArea: 'meinvodafone',
                    step: 2,
                    stepName: 'confirm deletion',
                    status: ['end', 'success'],
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'delete account', 'confirmation'],
                    outcome: '{{outcomeDeleteEmailStatus}},{{outcomeUserType}}'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.back.std-headline'
            }
        },
        confirmPermission: {
            name: 'einverstaendniserklaerung',
            route: '/verwaltung/einverstaendniserklaerung',
            titleKey: 'accountManagementModule.confirmPermission.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'VKD agreement'],
                    module: 'vkd agree data transfer',
                    step: 2,
                    stepName: 'confirm agreement',
                    status: ['end', 'success'],
                    context: 'agreed_from_account_mgmt',
                    type: 'myvodafone:transaction'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            }
        },
        deleteAccount: {
            name: 'loeschen',
            route: '/verwaltung/loeschen',
            titleKey: 'accountManagementModule.deleteAccount.std-headline',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'delete account',
                    siteArea: 'meinvodafone',
                    step: 1,
                    stepName: 'trigger deletion',
                    status: ['start'],
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'delete account', 'trigger deletion']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        manageContractAndSubscription: {
            name: 'vertragsverwaltung',
            route: '/verwaltung/vertragsverwaltung'
        },
        accountOverview: {
            name: 'ueberblick',
            route: '/verwaltung/ueberblick',
            titleKey: 'accountManagementModule.accountOverview.std-headline',
            notifyBeforeUnload: false,
            accordions: {
                manageAccountData: {
                    tracking: {
                        trackingType: 'page',
                        trackingTags: {
                            siteArea: 'meinvodafone',
                            siteStructure: ['vertragsverwaltung', 'manage userdata']
                        }
                    },
                    id: '1',
                    name: 'manage contact and access data'
                },
                manageContractsSubscriptions: {
                    tracking: {
                        trackingType: 'page',
                        trackingTags: {
                            siteStructure: ['vertragsverwaltung', 'manage contracts'],
                            siteArea: 'meinvodafone'
                        }
                    },
                    id: '2',
                    name: 'manage contracts and subscriptions'
                },
                similarAccounts: {
                    tracking: {
                        trackingType: 'page',
                        trackingTags: {
                            siteStructure: ['vertragsverwaltung', 'andere-verwalter']
                        }
                    },
                    id: '3',
                    name: 'other admins'
                },
                tanGeneration: {
                    tracking: {
                        trackingType: 'page',
                        trackingTags: {
                            siteStructure: ['vertragsverwaltung', 'online-tan']
                        }
                    },
                    id: '4',
                    name: 'customer service tan'
                },
                callback: {
                    tracking: {
                        trackingType: 'page',
                        trackingTags: {
                            module: 'callback',
                            siteStructure: ['vertragsverwaltung', 'termin']
                        }
                    },
                    id: 'termin',
                    name: 'Rückruf-Termine'
                }
            },
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung'],
                    module: 'account management overview'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'accountManagementModule.accountOverview.backButton.text-body',
                navigationLink: '/meinvodafone/services/'
            }
        },
        displayUserData: {
            name: 'accountdaten',
            route: '/verwaltung/accountdaten'
        },
        renameContractAndSubscription: {
            name: 'vertrag_umbenennen',
            route: '/verwaltung/vertrag_umbenennen',
            titleKey: 'accountManagementModule.renameContractOrSubscription.std-headline',
            notifyBeforeUnload: true,
            access: {
                accessLevel: 'contractOrSubscription',
                accessList: ['marie', 'bart', 'peter', 'paul', 'mark', 'martin', 'kay']
            },
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: ['manage account', 'rename contract'],
                    module: 'rename contract'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },
        changeUsernameOrPassword: {
            name: 'login_aendern',
            route: '/verwaltung/login_aendern',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'change username+pw', 'enter new data'],
                    siteArea: 'meinvodafone',
                    module: 'change username+pw',
                    step: 1,
                    stepName: 'edit username+pw',
                    status: ['start']
                },
                transactionState: 'start'
            },
            titleKey: 'accountManagementModule.changeUsernameOrPassword.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        changeUsernameOrPasswordConfirm: {
            name: 'accountdaten_bestaetigt',
            route: '/verwaltung/accountdaten_bestaetigt',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'change username+pw', 'confirmation'],
                    module: 'change username+pw',
                    step: 2,
                    stepName: 'confirmation changed username+pw',
                    outcome: '{{outcomeChangeUsernameOrPasswordConfirm}}',
                    status: ['end', 'success']
                },
                transactionState: 'start'
            },
            titleKey: 'accountManagementModule.changeUsernameOrPassword.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        changeContactNumber: {
            name: 'rufnummer_aendern',
            route: '/verwaltung/rufnummer_aendern',
            titleKey: 'accountManagementModule.changeContactNumber.std-headline',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'change phonenumber', 'edit phonenumber'],
                    module: 'change phonenumber',
                    step: 1,
                    stepName: 'edit phonenumber',
                    status: ['start'],
                    type: 'myvodafone:transaction'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        changeContactNumberConfirm: {
            name: 'rufnummer_bestaetigt',
            route: '/verwaltung/rufnummer_bestaetigt',
            titleKey: 'accountManagementModule.changeContactNumberConfirm.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'change phonenumber', 'confirmation'],
                    module: 'change phonenumber',
                    step: 2,
                    stepName: 'confirmation changed phonenumber',
                    status: ['end', 'success']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        changePersonalData: {
            name: 'nutzerdaten_aendern',
            route: '/verwaltung/nutzerdaten_aendern',
            titleKey: 'accountManagementModule.changePersonalData.std-headline',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'manage personal data', 'edit userdata'],
                    step: 1,
                    stepName: 'edit userdata',
                    status: ['start'],
                    type: 'myvodafone:transaction',
                    module: 'change userdata'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        changePersonalDataConfirm: {
            name: 'nutzerdaten_bestaetigt',
            route: '/verwaltung/nutzerdaten_bestaetigt',
            titleKey: 'accountManagementModule.changePersonalDataConfirm.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'manage personal data', 'confirmation'],
                    module: 'change userdata',
                    step: 2,
                    stepName: 'confirmation changed userdata',
                    status: ['end', 'success']
                },
                transactionState: 'end'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        removeContract: {
            name: 'vertrag_entfernen',
            route: '/verwaltung/vertrag_entfernen',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    module: 'remove contract',
                    siteStructure: ['manage account', 'remove contract']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },
        removeSubscription: {
            name: 'teilnehmer_entfernen',
            route: '/verwaltung/teilnehmer_entfernen',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    module: 'remove contract',
                    siteStructure: ['manage account', 'remove contract']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },
        changeSecurityQuestions: {
            name: 'sicherheitsfragen',
            route: '/verwaltung/sicherheitsfragen',
            stepName: 'submit-security-questions',
            titleKey: 'accountManagementModule.changeSecurityQuestions.std-headline',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['contract management', 'manage userdata', 'manage security questions', 'edit'],
                    status: ['start'],
                    module: 'change security q+a'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        reviewSecurityQuestions: {
            name: 'sicherheitsfragen_ueberpruefen',
            route: '/verwaltung/sicherheitsfragen_ueberpruefen',
            stepName: 'submit-security-questions',
            notifyBeforeUnload: true,
            titleKey: 'accountManagementModule.changeSecurityQuestions.std-headline',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'manage security questions', 'review'],
                    module: 'change security q+a'
                },
                transactionState: 'progressing'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        confirmSecurityQuestions: {
            name: 'sicherheitsfragen_bestaetigt',
            route: '/verwaltung/sicherheitsfragen_bestaetigt',
            stepName: 'submit-security-questions',
            titleKey: 'accountManagementModule.changeSecurityQuestions.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'manage security questions', 'confirmation'],
                    name: 'change security q+a',
                    step: 2,
                    stepName: 'confirm change of security q+a',
                    status: ['end', 'success'],
                    type: 'myvodafone:transaction',
                    module: 'change security q+a'
                },
                transactionState: 'end'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        }
    },
    editAccess: {
        name: 'zugriff',
        route: '/zugriff',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.revokeAccess',
        pageType: 'oneTimeTransaction',
        notifyBeforeUnload: true,
        confirmRemoveAdmin: {
            name: 'zugriff_entfernen',
            route: '/zugriff/zugriff_entfernen',
            stepName: 'confirm-remove-admin',
            titleKey: 'editAccessModule.confirmRemoveAdmin.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'edit access',
                    step: 1,
                    siteStructure: ['manage account', 'edit access'],
                    stepName: 'confirm to remove admin',
                    status: ['start']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },
        submitConfirmation: {
            name: 'zugriff_entfernt',
            route: '/zugriff/zugriff_entfernt',
            stepName: 'submit-confirmation',
            confirmationPage: true,
            titleKey: 'editAccessModule.submitConfirmation.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'edit access',
                    step: 2,
                    siteStructure: ['manage account', 'edit access'],
                    stepName: 'confirmation admin removal',
                    status: ['end', 'success']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions',
                mobileBtnLabelKey: 'editAccessModule.submitConfirmation.backbutton.std-headline'
            }
        }
    },
    forgetUsername: {
        name: 'benutzername',
        route: '/benutzername',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.usernameForgotten',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        notifyBeforeUnload: true,
        chooseMethods: {
            name: 'auswahl_methode',
            route: '/benutzername/auswahl_methode',
            stepName: 'choose-method',
            titleKey: 'forgetUsernameModule.chooseMethod.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose method'],
                    module: 'username forgotten',
                    step: 1,
                    stepName: 'choose method',
                    status: ['start']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login/zugangsdaten_vergessen'
            }
        },
        recoverNotPossible: {
            name: 'nicht_moeglich',
            route: '/benutzername/nicht_moeglich',
            titleKey: 'forgetUsernameModule.recoverNotPossible.std-headline',
            stepName: 'recover-not-possible',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: [
                        'login',
                        'forgotten login data',
                        'choose alternative methode',
                        'choose contract type',
                        'no contract - no us reset possible'],
                    siteArea: 'meinvodafone'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline'
            }
        },
        alternativeEmail: {
            name: 'alternative_email',
            route: '/benutzername/alternative_email',
            stepName: 'enter-email',
            titleKey: 'forgetUsernameModule.alternativeEmail.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'enter e-mail'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 2,
                    stepName: 'enter e-mail',
                    status : []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/benutzername/auswahl_methode'
            }
        },
        chooseContract: {
            name: 'auswahl_vertragstyp',
            route: '/benutzername/auswahl_vertragstyp',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.chooseContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 3,
                    stepName: 'choose contract type',
                    status : []
                },
                transactionState: 'start'
            }
        },
        vodafoneContract: {
            name: 'vertrag_vf',
            route: '/benutzername/vertrag_vf',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.vodafoneContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'choose VF contract'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step: 4,
                    stepName: 'choose vf contract type',
                    status : []
                }
            }
        },
        dslContract: {
            name: 'vertrag_dsl',
            route: '/benutzername/vertrag_dsl',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.dslContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'choose VF contract', 'edit DSL contract'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 5,
                    stepName: 'edit vf dsl contract' ,
                    status : []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/vertrag_vf'
            }
        },
        mobileContract: {
            name: 'vertrag_mobil',
            route: '/benutzername/vertrag_mobil',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.mobileContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'choose VF contract', 'edit mobile contract'],
                    module: 'username forgotten',
                    step : 5,
                    stepName: 'edit vf mobile contract',
                    status : []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/vertrag_vf'
            }
        },
        mobileEnterCredentials: {
            name: 'vertragsdaten_mobil',
            route: '/benutzername/vertragsdaten_mobil',
            stepName: 'enter-credentials',
            titleKey: 'forgetUsernameModule.mobileEnterCredentials.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data',
                    step: '4b',
                    siteStructure: ['login data', 'forgotten login data'],
                    stepName: 'username: edit mobile contract',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/vertrag_vf'
            }
        },
        cableContract: {
            name: 'vertrag_kabel',
            route: '/benutzername/vertrag_kabel',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.cableContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'edit VKD contract'],
                    module: 'username forgotten',
                    step: 5,
                    stepName: 'edit vkd contract',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/auswahl_vertragstyp'
            }
        },
        emailConfirmation: {
            name: 'email',
            route: '/benutzername/email',
            titleKey: 'forgetUsernameModule.emailConfirmation.std-headline',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'e-mail sent'],
                    module: 'username forgotten',
                    step: 8,
                    stepName: 'confirmation e-mail sent',
                    outcome: '{{outcome}}',
                    status: ['end', 'success']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'forgetUsernameModule.emailConfirmation.button.std-headline'
            }

        },
        terminateHotline: {
            name: 'fehler_hotline',
            route: '/benutzername/fehler_hotline',
            titleKey: 'forgetUsernameModule.callHotline.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'call hotline'],
                    module: 'username forgotten',
                    stepName: 'not possible - call hotline',
                    step: 8,
                    status: ['end', 'failure'],
                    outcome: '{{outcome}}'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'forgetUsernameModule.callHotline.button.std-headline',
                navigationLink: '/login'
            }
        },
        alternativeProcess: {
            name: 'alternative_vertrag',
            route: '/benutzername/alternative_vertrag',
            stepName: 'enter-alternative-process',
            titleKey: 'forgetUsernameModule.alternativeProcess.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'username forgotten',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', '{{siteStructureAlternativeProcess1}}', '{{siteStructureAlternativeProcess2}}'],
                    siteArea: 'meinvodafone',
                    step: '{{stepAlternativeProcess}}',
                    stepName: '{{stepNameAlternativeProcess}}',
                    status : []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login'
            }

        }
    },
    updateEmail: {
        name: 'email',
        route: '/email',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.updateEmail',
        notifyBeforeUnload: true,
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
        },
        changeEmail: {
            name: 'aendern',
            route: '/email/aendern',
            stepName: 'enter-email',
            titleKey: 'updateEmailModule.std-headline',
            access: {
                accessLevel: 'account'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'update verify e-mail',
                    step: 1,
                    siteStructure: ['contact data', 'update verify e-mail'],
                    stepName: 'enter e-mail',
                    status: ['start']
                },
                transactionState: 'start'
            }
        },
        confirmation: {
            name: 'bestaetigung',
            route: '/email/bestaetigung',
            stepName: 'confirmation',
            titleKey: 'updateEmailModule.std-headline',
            access: {
                accessLevel: 'account'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'update verify e-mail',
                    step: 2,
                    siteStructure: ['contact data', 'update verify e-mail'],
                    stepName: 'confirm e-mail sent',
                    status: []
                },
                transactionState: 'start'
            },
            confirmationPage: true,
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        verifyEmail: {
            name: 'verifizieren',
            route: '/email/verifizieren',
            notifyBeforeUnload: false,
            navigation: {
                topLink: true,
                mobileBtn: false,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData',
                mobileBtnLabelKey: 'common.buttons.goToMeinVodafone.std-headline'
            },
            tracking: {
                trackingTags: {
                    module: 'update verify e-mail',
                    step: 3,
                    siteStructure: ['contact data', 'update verify e-mail'],
                    status: ['end', 'success']
                },
                transactionState: 'start'
            }
        }
    },
    removeEmail: {
        name: 'email-loeschen',
        route: '/email-loeschen',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.removeEmail',
        access: {
            accessLevel: 'account'
        },
        notifyBeforeUnload: true,
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
        },
        reviewEmail: {
            name: 'review',
            route: '/email-loeschen/review',
            titleKey: 'RemoveEmailModule.std-headline',
            stepName: 'email-loeschen review',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    module: 'Delete Vodafone Email account',
                    step: 1,
                    stepName: 'email-loeschen review',
                    siteStructure: ['vertragsverwaltung', 'email', 'email loeschen', 'review'],
                    status: ['start']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData',
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline'
            }
        },
        confirmation: {
            name: 'confirmation',
            route: '/email-loeschen/confirmation',
            titleKey: 'RemoveEmailModule.std-headline',
            notifyBeforeUnload: false,
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData',
                mobileBtnLabelKey: 'common.buttons.back.std-headline'
            }
        }
    },
    mergeUser: {
        name: 'zusammenlegung',
        route: '/zusammenlegung',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.mergeUser',
        titleKey: 'mergeUserModule.std-headline',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/verwaltung/ueberblick'
        },
        notifyBeforeUnload: true,
        login: {
            name: 'login',
            route: '/zusammenlegung/login',
            titleKey: 'mergeUserModule.login.std-headline',
            stepName: 'merge-login',
            tracking: {
                trackingTags: {
                    module: 'merge user',
                    step: 1,
                    siteStructure: ['manage account', 'merge user'],
                    stepName: 'enter login for B',
                    status: ['start']
                }
            }
        },
        dataTransferPermission: {
            name: 'einverstaendniserklaerung',
            route: '/zusammenlegung/einverstaendniserklaerung',
            titleKey: {
                permission: 'mergeUserModule.confirmNoDataTransfer.std-headline',
                confirmation: 'mergeUserModule.confirmNoDataTransfer.confirmation.std-headline',
                DEFAULT: 'permission'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['vertragsverwaltung', 'manage userdata', 'VKD agreement'],
                    module: 'vkd agree data transfer',
                    step: 2,
                    stepName: 'confirm agreement',
                    status: ['end', 'success'],
                    context: 'agreed_from_merge_user',
                    type: 'myvodafone:transaction'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            }
        },
        chooseUsername: {
            name: 'auswahl',
            route: '/zusammenlegung/auswahl',
            titleKey: 'mergeUserModule.chooseUserName.std-headline',
            stepName: 'choose-username',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'merge user',
                    step: 3,
                    siteStructure: ['manage account', 'merge user'],
                    stepName: 'choose continued account',
                    status: []
                }
            }
        },
        mergeNotPossible: {
            name: 'nicht_moeglich',
            route: '/zusammenlegung/nicht_moeglich',
            titleKey: 'mergeUserModule.mergeNotPossible.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'merge user',
                    step: 1,
                    siteStructure: ['manage account', 'merge user'],
                    stepName: 'no merge possible',
                    status: ['end', 'failure']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            }
        },
        confirmMigration: {
            name: 'bestaetigen',
            route: '/zusammenlegung/bestaetigen',
            titleKey: 'mergeUserModule.confirmMigration.std-headline',
            stepName: 'confirm-migration',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'merge user',
                    step: 4,
                    siteStructure: ['manage account', 'merge user'],
                    stepName: 'set password + confirm merge',
                    status: []
                }
            }
        },
        mergeUserConfirmation: {
            name: 'bestaetigt',
            route: '/zusammenlegung/bestaetigt',
            confirmationPage: true,
            titleKey: 'mergeUserModule.mergeUserConfirmation.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'merge user',
                    step: 5,
                    siteStructure: ['manage account', 'merge user'],
                    stepName: 'confirmation',
                    status: ['end', 'success']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'mergeUserModule.mergeUserConfirmation.overview.backButton.std-headline',
                navigationLink: '/verwaltung/ueberblick'
            }
        }
    },
    logout: {
        targetUrl: '/meinvodafone/meinvf_logout.html',
        timeoutUrl: '/meinvodafone/meinvf_timeout.html',
        tracking: {
            trackingTags: {
                module: 'logout',
                siteStructure: ['logout', 'manual logout'],
                stepName: 'login',
                step: 1,
                status: ['start']
            }
        }
    },
    enterprise: {
        name: 'business',
        route: '/business',
        tan: {
            name: 'tan',
            route: '/business/tan',
            access: {
                accessLevel: 'account',
                accessList: ['eric', 'emma']
            },
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    module: 'tan generation',
                    siteStructure: ['manage account', 'tan generation']
                }
            }
        }
    },
    acceptUserInvitation: {
        name: 'einladung',
        route: '/einladung',
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.acceptUserInvitation',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/einladung/annehmen'
        },
        chooser: {
            name: 'annehmen',
            route: '/einladung/annehmen',
            notifyBeforeUnload: false,
            stepName: 'choose-method',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 1,
                    stepName: 'invitation landing page',
                    status: ['start'],
                    siteStructure: ['register user', 'accept invitation']
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        submitPersonalData: {
            name: 'persoenliche_daten',
            route: '/einladung/persoenliche_daten',
            stepName: 'submit-personal-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 2,
                    stepName: 'personal data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        submitContactData: {
            name: 'kontaktdaten',
            route: '/einladung/kontaktdaten',
            stepName: 'submit-contact-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 3,
                    stepName: 'contact data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        submitUserData: {
            name: 'accountdaten',
            route: '/einladung/accountdaten',
            stepName: 'submit-user-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 4,
                    stepName: 'user data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        reviewData: {
            name: 'uebersicht',
            route: '/einladung/uebersicht',
            stepName: 'review-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 5,
                    stepName: 'review account data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        confirmation: {
            name: 'bestaetigung',
            route: '/einladung/bestaetigung',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 6,
                    stepName: 'confirmation account set up',
                    status: ['end', 'success'],
                    siteStructure: ['register user', 'accept invitation']
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false
            }
        }
    },
    changeONBUsername: {
        name: 'benutzername_ONB',
        route: '/benutzername_ONB',
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.changeONBName',
        tracking: {
            trackingType: 'transaction',
            trackingTags: {
                module: 'accept invitation',
                step: '1b',
                stepName: 'change username',
                status: [],
                siteStructure: ['register user', 'accept invitation']
            }
        },
        changeUsername: {
            route: '/benutzername_ONB',
            stepName: 'change-username'
        }
    },
    paymentProvider: {
        name: 'payment1',
        route: '/payment1',
        notifyBeforeUnload: true,
        titleKey: 'paymentProviderModule.std-headline',
        chooseAmount: {
            name: 'aufladebetrag',
            route: '/payment1/aufladebetrag',
            notifyBeforeUnload: true,
            titleKey: 'paymentProviderModule.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['privatkunden', 'callya', 'top up', 'home'],
                    isCustomSiteStructure: true,
                    siteArea: 'callya top up',
                    businessTransactionType: 'callya top up',
                    flowType: 'callya top up flow',
                    lineOfCustomer: 'consumer',
                    name: 'callya top up',
                    type: 'callya top up',
                    step: '1',
                    stepName: 'home',
                    status: ['start']
                }
            }
        },
        register: {
            name: 'registrieren',
            route: '/payment1/registrieren',
            notifyBeforeUnload: true,
            titleKey: 'paymentProviderModule.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['privatkunden', 'callya', 'top up', 'registration'],
                    isCustomSiteStructure: true,
                    siteArea: 'callya top up',
                    businessTransactionType: 'callya top up',
                    lineOfCustomer: 'consumer',
                    name: 'callya top up',
                    type: 'callya top up',
                    step: '2',
                    stepName: 'registration {{authenticated}}',
                    status: [],
                    oOrder: {
                        aProducts: {
                            sku: 'callya top up {{amount}}',
                            name: 'callya top up {{amount}}',
                            type: 'topup',
                            priceOnce: '{{price}}'
                        },
                        aPropositions: {
                            id: 1,
                            units: 1,
                            name: 'callya top up',
                            targetAudience: 'consumer',
                            retention: 'newcontract',
                            productType: 'debit',
                            productLine: 'voice',
                            productCategory: 'topup'
                        }
                    }
                }
            }
        },
        personalData: {
            name: 'persoenliche-daten',
            route: '/payment1/persoenliche-daten',
            notifyBeforeUnload: true,
            titleKey: 'paymentProviderModule.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['privatkunden', 'callya', 'top up', 'personal data'],
                    isCustomSiteStructure: true,
                    siteArea: 'callya top up',
                    businessTransactionType: 'callya top up',
                    lineOfCustomer: 'consumer',
                    name: 'callya top up',
                    type: 'callya top up',
                    step: '3',
                    stepName: 'personal data {{authenticated}}',
                    status: [],
                    oOrder: {
                        aProducts: {
                            sku: 'callya top up {{amount}}',
                            name: 'callya top up {{amount}}',
                            type: 'topup',
                            priceOnce: '{{price}}'
                        },
                        aPropositions: {
                            id: 1,
                            units: 1,
                            name: 'callya top up',
                            targetAudience: 'consumer',
                            retention: 'newcontract',
                            productType: 'debit',
                            productLine: 'voice',
                            productCategory: 'topup'
                        }
                    }
                }
            }
        },
        payment: {
            name: 'bezahlmethode',
            route: '/payment1/bezahlmethode',
            notifyBeforeUnload: true,
            titleKey: 'paymentProviderModule.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['privatkunden', 'callya', 'top up', 'payment'],
                    isCustomSiteStructure: true,
                    siteArea: 'callya top up',
                    businessTransactionType: 'callya top up',
                    lineOfCustomer: 'consumer',
                    name: 'callya top up',
                    type: 'callya top up',
                    step: '4',
                    stepName: 'payment {{authenticated}}',
                    status: [],
                    hasChangedMsisdn: '{{hasChangedMsisdn}}',
                    hasChangedEmail: '{{hasChangedEmail}}',
                    oOrder: {
                        aProducts: {
                            sku: 'callya top up {{amount}}',
                            name: 'callya top up {{amount}}',
                            type: 'topup',
                            priceOnce: '{{price}}'
                        },
                        aPropositions: {
                            id: 1,
                            units: 1,
                            name: 'callya top up',
                            targetAudience: 'consumer',
                            retention: 'newcontract',
                            productType: 'debit',
                            productLine: 'voice',
                            productCategory: 'topup'
                        }
                    }
                }
            }
        },
        review: {
            name: 'uebersicht',
            route: '/payment1/uebersicht',
            notifyBeforeUnload: false,
            titleKey: 'paymentProviderModule.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['privatkunden', 'callya', 'top up', 'review'],
                    isCustomSiteStructure: true,
                    siteArea: 'callya top up',
                    businessTransactionType: 'callya top up',
                    lineOfCustomer: 'consumer',
                    name: 'callya top up',
                    type: 'callya top up',
                    step: '5',
                    stepName: 'review {{authenticated}}',
                    status: [],
                    oOrder: {
                        aProducts: {
                            sku: 'callya top up {{amount}}',
                            name: 'callya top up {{amount}}',
                            type: 'topup',
                            priceOnce: '{{price}}'
                        },
                        aPropositions: {
                            id: 1,
                            units: 1,
                            name: 'callya top up',
                            targetAudience: 'consumer',
                            retention: 'newcontract',
                            productType: 'debit',
                            productLine: 'voice',
                            productCategory: 'topup'
                        }
                    }
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },
        creditCardData: {
            name: 'creditCardData',
            route: '/payment1/creditCardData',
            notifyBeforeUnload: false,
            titleKey: 'paymentProviderModule.std-headline'
        },
        cvcCreditdCard: {
            name: 'cvcCreditCard',
            route: '/payment1/cvcCreditCard',
            notifyBeforeUnload: false,
            titleKey: 'paymentProviderModule.std-headline'
        },
        confirmation: {
            name: 'bestaetigung',
            route: '/payment1/bestaetigung',
            notifyBeforeUnload: true,
            titleKey: 'paymentProviderModule.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['privatkunden', 'callya', 'top up', 'confirmation'],
                    isCustomSiteStructure: true,
                    siteArea: 'callya top up',
                    businessTransactionType: 'callya top up',
                    lineOfCustomer: 'consumer',
                    name: 'callya top up',
                    type: 'callya top up',
                    step: '6',
                    stepName: 'confirmation {{authenticated}}',
                    status: ['end', 'success'],
                    oOrder: {
                        aProducts: {
                            sku: 'callya top up {{amount}}',
                            name: 'callya top up {{amount}}',
                            type: 'topup',
                            priceOnce: '{{price}}'
                        },
                        aPropositions: {
                            id: 1,
                            units: 1,
                            name: 'callya top up',
                            targetAudience: 'consumer',
                            retention: 'newcontract',
                            productType: 'debit',
                            productLine: 'voice',
                            productCategory: 'topup'
                        },
                        paymentMethodOnce: '{{paymentMethodOnce}}',
                        orderID: '{{orderID}}'
                    }
                }
            }
        }
    },
    topupPayment: {
        name: 'payment',
        route: '/payment',
        titleKey: '',
        access: {
            accessLevel: 'account'
        },
        chooseAmount: {
            name: 'aufladebetrag',
            route: '/payment/aufladebetrag',
            titleKey: 'topupPaymentModule.std-headline',
            tracking: {
            },
            notifyBeforeUnload: true
        },
        review: {
            name: 'uebersicht',
            route: '/payment/uebersicht',
            titleKey: 'topupPaymentModule.std-headline',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['self service payment', 'payment method'],
                    siteArea: 'mein vodafone payment',
                    type: 'self service payment',
                    name: 'callya top up',
                    step: '1',
                    stepName: 'payment method',
                    status: ['start'],
                    oOrder: {
                        aProducts: {
                            sku: 'callya top up {{amount}}',
                            name: 'callya top up {{amount}}',
                            type: 'topup',
                            priceOnce: '{{price}}',
                            units: 1,
                            proposition: 1
                        },
                        aPropositions: {
                            id: 1,
                            name: 'callya top up',
                            targetAudience: 'consumer',
                            retention: 'self service payment',
                            productType: 'debit',
                            productLine: 'voice',
                            productCategory: 'topup',
                            units: 1
                        }
                    },
                    businessTransactionType: 'callya top up',
                    lineOfCustomer: 'consumer'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/payment/aufladebetrag'
            }
        },
        confirm: {
            name: '/confirm',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/payment/aufladebetrag'
            }
        },
        navigation: {
            topLink: true,
            mobileBtn: true,
            mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
            navigationLink: '/verwaltung/ueberblick?accordion=SenderHistoryAccordionData'
        }
    },
    gdpr: {
        name: 'datenschutz-einstellungen',
        route: 'datenschutz-einstellungen',
        allInOneReview: {
            name: 'persoenliche-angebote-alle',
            route: '/datenschutz-einstellungen/persoenliche-angebote-alle',
            titleKey: 'Persönliche Angebote einstellen',
            access: {
                accessLevel: 'account'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'CPM NBA overall {{nbaStatus:queryParam}}',
                    step: 1,
                    siteStructure: ['teilnehmer', 'datenschutz-einstellungen', 'CPM NBA overall {{nbaStatus:queryParam}} start'],
                    stepName: 'CPM NBA overall {{nbaStatus:queryParam}} start',
                    status: ['start']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },

        allInOneConfirmation: {
            name: 'persoenliche-angebote-alle-bestaetigung',
            route: '/datenschutz-einstellungen/persoenliche-angebote-alle-bestaetigung',
            stepName: 'confirmation',
            titleKey: 'Persönliche Angebote einstellen',
            access: {
                accessLevel: 'account'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'CPM NBA overall {{nbaStatus:queryParam}}',
                    step: 2,
                    siteStructure: ['teilnehmer', 'datenschutz-einstellungen', 'CPM NBA {{nbaStatus:queryParam}} Confirmation'],
                    stepName: 'CPM NBA {{nbaStatus:queryParam}} Confirmation',
                    status: ['end', 'success']
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/verwaltung/ueberblick?accordion=manageContractsSubscriptions'
            }
        },
        customerDataDownload: {
            name: 'kundendaten-herunterladen',
            route: '/datenschutz-einstellungen/kundendaten-herunterladen',
            titleKey: 'gdprModule.customerDataDownload.std-headline',
            access: {
                accessLevel: 'contract',
                accessList: ['bart', 'kay', 'mark', 'marie', 'paul', 'peter']
            },
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    module: 'gdpr',
                    siteStructure: ['vertragsdaten', 'kundendaten herunterladen']
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/meinvodafone/services/vertragsdaten?accordion=kundendaten-herunterladen'
            }
        },
        gdbrOneByOne: {
            name: 'persoenliche-angebote-mobil',
            route: '/datenschutz-einstellungen/persoenliche-angebote-mobil',
            titleKey: 'Datenschutz-Einstellungen',
            access: {
                accessLevel: 'subscription',
                accessList: ['kay', 'marie', 'peter', 'paul']
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/meinvodafone/services/upgrade/datenschutz-einstellungen'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'cpm-nba',
                    step: 1,
                    stepName: 'start',
                    status: ['start'],
                    siteStructure: ['Teilnehmer', 'datenschutz-einstellungen', 'cpm-nba', 'start']
                }
            }
        },
        gdbrOneByOneDSL: {
            name: 'persoenliche-angebote-dsl',
            route: '/datenschutz-einstellungen/persoenliche-angebote-dsl',
            titleKey: 'Datenschutz-Einstellungen',
            access: {
                accessLevel: 'contract',
                accessList: ['mark']
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/meinvodafone/services/vertragsdaten?accordion=datenschutz-einstellungen'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'cpm-nba',
                    step: 1,
                    stepName: 'start',
                    status: ['start'],
                    siteStructure: ['Teilnehmer', 'datenschutz-einstellungen', 'cpm-nba', 'start']
                }
            }
        }
    },
    checkEmailValidation: {
        name: 'validate-email',
        route: '/validate-email',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.validateEmail',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: ''

        },
        validateEmail: {
            name: 'validation',
            route: '/validate-email/validation',
            titleKey: 'CheckEmailModule.std-headline',
            access: {
                accessLevel: 'account'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: ''

            },
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    module: 'checkEmailValidation',
                    siteStructure: ['login', 'force e-mail verification', 'validate email'],
                    siteArea: 'meinvodafone'

                }
            }
        }
    }
};
