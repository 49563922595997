var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { XSRFStrategy, BrowserXhr, ResponseOptions, XHRBackend, } from '@angular/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ValidationService } from './validation.service';
import { ProcessService } from './process.service';
import * as pagesConfig from '../../../config/pages-config';
import { AUTHENTICATION_ERROR_CODE, AUTHENTICATION_WHITELIST, ONELOGIN_URL } from './../../common/constants/Defines';
import { CustomErrorHandlerService } from '@vfde-fe/ng-modules';
import { environment } from '../../../environments/environment';
import { SpinnerService } from '@vfde-fe/ng-modules';
import { API_URLS } from 'src/app/common/constants/routes-config';
import { CoreAuthenticationService } from '@vfde-fe/ng-modules';
import { RoutesUtilsService } from '@vfde-fe/ng-modules';
import { Helper } from '@vfde-fe/ng-modules';
import { HEADER_CONTEXT_WHITE_LIST } from '@vfde-fe/ng-modules';
/** HttpInterceptor */
var HttpInterceptor = /** @class */ (function (_super) {
    __extends(HttpInterceptor, _super);
    function HttpInterceptor(_browserXHR, _baseResponseOptions, _xsrfStrategy, router, processService, validationService, customErrorHandlerService, spinnerService, authenticationService, routesUtilsService, helper) {
        var _this = _super.call(this, _browserXHR, _baseResponseOptions, _xsrfStrategy) || this;
        _this.router = router;
        _this.processService = processService;
        _this.validationService = validationService;
        _this.customErrorHandlerService = customErrorHandlerService;
        _this.spinnerService = spinnerService;
        _this.authenticationService = authenticationService;
        _this.routesUtilsService = routesUtilsService;
        _this.helper = helper;
        _this.pagesConfig = pagesConfig.config;
        return _this;
    }
    /** createConnection */
    HttpInterceptor.prototype.createConnection = function (request) {
        var _this = this;
        if (request.url.indexOf(environment.baseUrlNil) !== -1) {
            request.headers.append('x-vf-api', (new Date().valueOf()).toString());
            request.headers.append('x-vf-clientID', 'MyVFWeb');
        }
        if (HEADER_CONTEXT_WHITE_LIST.CONTEXT.indexOf(this.helper.getContext()) > -1
            && this.helper.detectIsUrlInWhiteList(request.url, HEADER_CONTEXT_WHITE_LIST.URLS)) {
            request.headers.append('X-USE-CONTEXT', this.helper.getContext());
        }
        var disableSpinner = request.headers.get('disableSpinner') === 'true';
        var loader = !disableSpinner ? this.spinnerService.startLoader() : null;
        var connection = _super.prototype.createConnection.call(this, request);
        // Check if url is one of the defined process urls to apply generic routing
        connection.response = connection.response.pipe(map(function (res) {
            if (loader) {
                _this.spinnerService.endLoader(loader, true);
            }
            if (_this.processService.checkIfProcessUri(request.url)) {
                // Successful Response;
                return _this.processSuccessResponse(res);
            }
            return res;
        }), catchError(function (error) {
            if (loader) {
                _this.spinnerService.endLoader(loader, false);
            }
            return _this.processErrorResponse(error);
        }));
        return connection;
    };
    /** processSuccessResponse */
    HttpInterceptor.prototype.processSuccessResponse = function (response) {
        var result = response.json();
        if (result.stepName) {
            var route = this.processService.handleGenericRouting(result.stepName);
            if (route) {
                this.validationService.addStepAttributes(result.parameters, result.stepName);
                if (this.processService.navigate) {
                    this.router.navigate([route]);
                }
                return response;
            }
            else {
                console.warn('Step name retrieved does not match to any route');
                return response;
            }
        }
        else if (result.lastStep) {
            return response;
        }
        else {
            return throwError('Next step not retrieved from BE');
        }
    };
    /**
     * processErrorResponse to handle generic error on level of application (404,500,401,403,etc..)
     */
    HttpInterceptor.prototype.processErrorResponse = function (response) {
        var _this = this;
        var errorCode = null;
        try {
            if (response.url.indexOf(environment.baseUrlNil) !== -1) {
                errorCode = response.json().faultMessage ? response.json().faultMessage.code : null;
            }
            else {
                errorCode = response.json().operationError ? response.json().operationError.code : null;
            }
        }
        catch (e) {
            // console.log(e);
            // do nothing and not to stop code sequence in case response in not in JSON format
            return throwError(response);
        }
        if (response.url.indexOf(environment.cmsConfigPath) > -1 || response.url.indexOf(environment.cmsErrorMessagesPath) > -1 || response.url.indexOf(environment.cmsCommonWording) > -1) {
            return throwError(response);
        }
        switch (response.status) {
            case 401:
                // redirect to login page
                var foundWhiteList_1 = false;
                if (errorCode === AUTHENTICATION_ERROR_CODE.MINT_AUTHENTICATION_REQUIRED) {
                    AUTHENTICATION_WHITELIST.forEach(function (item) {
                        if (_this.router.url.indexOf(_this.pagesConfig[item].route) > -1) {
                            foundWhiteList_1 = true;
                        }
                    });
                    if (foundWhiteList_1) {
                        return throwError(response);
                    }
                    else {
                        this.authenticationService.logout();
                        this.router.navigate([this.pagesConfig.login.route]);
                        return throwError(response);
                    }
                }
                else if (errorCode === AUTHENTICATION_ERROR_CODE.NIL_AUTHENTICATION_REQUIRED) {
                    window.location.href = API_URLS.LOGOUT.LOGOUT + '?targetURL=/' + ONELOGIN_URL + pagesConfig.config.login.route + '?targetURL=' + encodeURIComponent(encodeURIComponent(window.location.href));
                    return throwError(response);
                }
                return throwError(response);
            //   case 403:
            //     if(errorCode === AUTHORIZATION_ERROR_CODE.SECURITY_CHALLENGE_FAILED ){
            //         let queryParam:any = {
            //             accessLevel: this.processService.getStepUsingName(response.url).access.accessLevel
            //         };
            //         if(this.authenticationService.isAuthenticated()){
            //             queryParam.contractType = this.authenticationService.getCurrentUserModel().getActor();
            //         }
            //         console.log('QueryParam (403):',queryParam);
            //         this.router.navigate([pagesConfig.config.unauthorized.name], {
            //             queryParams: queryParam
            //         });
            //         return;
            //     } else {
            //         return Observable.throw(response);
            //     }
            default:
                var currentModule = this.processService.getCurrentModule() !== '' ? this.processService.getCurrentModule() : undefined;
                var currentUsecase = this.routesUtilsService.getCurrentUsecase() !== '' ? this.routesUtilsService.getCurrentUsecase() : undefined;
                if (!errorCode) {
                    this.customErrorHandlerService.getErrorDescription(currentModule, undefined, response.status.toString(), true, true, false, currentUsecase);
                    return throwError(response);
                }
                else {
                    this.customErrorHandlerService.getErrorDescription(currentModule, errorCode, response.status.toString(), true, true, false, currentUsecase);
                    return throwError(response);
                }
        }
    };
    return HttpInterceptor;
}(XHRBackend));
export { HttpInterceptor };
