import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CoreAuthenticationService, ONELOGIN_PATH } from '@vfde-fe/ng-modules';
import { config } from '../../../config/pages-config';
import { API_URLS } from '../constants/routes-config';
import { RoutesService } from '../utils/routes.service';
import { DISABLE_DEEP_LINKS_WHITE_LIST, MODULE_NAMES } from './../constants/Defines';

/** DisableDeepLinksGuard */
@Injectable()
export class DisableDeepLinksGuard implements CanActivate {

    constructor (private router: Router, private authenticationService: CoreAuthenticationService, private routesService: RoutesService) { }

    /**
     * canActivate is checking if the use came from deeplink or not
     * if the use came from deeplink then we will navigate him to login page
     * @param route - snapshot of current route data
     * @param state - snapshot of current state data
     * @returns {Observable<boolean>}
     */
    public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let guaranteeAccess: boolean = false;

        const cleanUrl = window.location.href.split('#')[0].split('?')[0];

        /* check if the user is comming from the login state */
        if (cleanUrl.endsWith(ONELOGIN_PATH + config.login.route)) {

            /* if the user authenticated redirect to the ecare dashboard */
            if (this.authenticationService.isAuthenticated()) {

                window.location.href = window.location.origin + API_URLS.ECARE_ROUTES.DASHBOARD;

                return false;
            } else {

                /* if the user is not authenticated check if the user is comming from one of the states which found in this white list and give him the acess */
                DISABLE_DEEP_LINKS_WHITE_LIST.forEach((routeInWhiteList) => {

                    if (state.url.indexOf(routeInWhiteList) >= 0) {

                        guaranteeAccess = true;
                    }
                });

                /* if the access is not guaranted redirect the user to the login page again*/
                if (!guaranteeAccess) {

                    this.router.navigate([MODULE_NAMES.loginModule], {});

                    return false;
                } else {

                    /* if guranted allow the access to the desired state*/
                    return true;
                }
            }
        }

        /* check if the user is comming from the current state detect if the back button is not pressed and navigate to the login module */
        if (window.location.href.indexOf(state.url.split('#')[0].split('?')[0]) >= 0) {
            if (!this.routesService.getbackButtonPressedFlag()) {
                this.router.navigate([MODULE_NAMES.loginModule], {
                });

                return false;
            }
        }

        return true;
    }

}
