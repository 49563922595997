/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./general-error.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@vfde-fe/ng-modules/vfde-fe-ng-modules.ngfactory";
import * as i3 from "@vfde-fe/ng-modules";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./general-error.component";
var styles_GeneralErrorComponent = [i0.styles];
var RenderType_GeneralErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeneralErrorComponent, data: {} });
export { RenderType_GeneralErrorComponent as RenderType_GeneralErrorComponent };
export function View_GeneralErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "navigation", [], null, null, null, i2.View_NavigationComponent_0, i2.RenderType_NavigationComponent)), i1.ɵdid(1, 704512, null, 0, i3.NavigationComponent, [i4.Router, i3.RoutesUtilsService, i1.Renderer2, i5.TranslateService, i3.CoreConfigurationsLoaderService, i3.StepperService], { navigationDetails: [0, "navigationDetails"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "transaction-header", [], null, null, null, i2.View_TransactionHeaderComponent_0, i2.RenderType_TransactionHeaderComponent)), i1.ɵdid(3, 245760, null, 0, i3.TransactionHeaderComponent, [i4.Router, i3.RoutesUtilsService, i3.CommonCacheService, i3.UserCacheService, i3.SimCacheService, i3.UserService, i5.TranslateService, i4.ActivatedRoute, i6.LocationStrategy], { defaultTitleKey: [0, "defaultTitleKey"], accessLevel: [1, "accessLevel"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "standard-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(6, 901120, null, 0, i3.NotificationComponent, [i4.Router, i5.TranslateService, i3.RoutesUtilsService, i6.PlatformLocation, i3.Helper], { notificationDetails: [0, "notificationDetails"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "div", [["class", "fm-buttons"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigationDetails; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; var currVal_2 = _co.accessLevel; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.error; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_GeneralErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-general-error", [], null, null, null, View_GeneralErrorComponent_0, RenderType_GeneralErrorComponent)), i1.ɵdid(1, 49152, null, 0, i7.GeneralErrorComponent, [i3.CustomErrorHandlerService, i3.RoutesUtilsService], null, null)], null, null); }
var GeneralErrorComponentNgFactory = i1.ɵccf("app-general-error", i7.GeneralErrorComponent, View_GeneralErrorComponent_Host_0, {}, {}, []);
export { GeneralErrorComponentNgFactory as GeneralErrorComponentNgFactory };
