import { Injectable, NgZone } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as pagesConfig from '../../../config/pages-config';
import { API_URLS } from '../../common/constants/routes-config';
import { LOGOUT_IDEL_CONF } from './../constants/Defines';

declare var window: any;
declare var androidObject: any;

/** IdleService */
@Injectable()
export class IdleService implements OnDestroy {

    /** lastPing */
    public lastPing?: Date = null;
    constructor (private idle: Idle, private keepalive: Keepalive, private ngZone: NgZone) { }

    /** start */
    public start () {
        this.ngZone.runOutsideAngular(() => {
            // sets an idle timeout.
            this.idle.setIdle(LOGOUT_IDEL_CONF.IDEL_TIME);
            // sets a timeout period. after this period of inactivity, the user will be considered timed out.
            this.idle.setTimeout(LOGOUT_IDEL_CONF.TIMEOUT_TIME);
            // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

            this.idle.onIdleStart.subscribe(() => {
                this.ngZone.run(() => console.log('You\'ve gone idle!'));
            });

            this.idle.onTimeout.subscribe(() => {
                this.ngZone.run(() => {
                    console.log('onTimeout action ');
                    try {
                        console.log('onTimeout action for android :)');
                        androidObject.renewSession(JSON.stringify({evenName: 'idle', details: 'idle fire for android'}));
                    } catch (e) {
                        // do nothing and ignore event
                    }
                    try {
                        console.log('onTimeout action for IOS :)');
                        window.webkit.messageHandlers.nativeCallbackHandler.postMessage(JSON.stringify({evenName: 'idle', details: 'idle fire for ios'}));
                    } catch (e) {
                        // do nothing and ignore event
                    }
                    window.location.href = API_URLS.LOGOUT.LOGOUT + '?targetURL=' + pagesConfig.config.logout.timeoutUrl;

                });
            });

            // sets the ping interval to 15 seconds
            this.keepalive.interval(LOGOUT_IDEL_CONF.PING_INTERVAL);

            this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
            this.reset();
        });

    }

    /** reset */
    public reset () {
        this.ngZone.runOutsideAngular(() => {
            this.idle.watch();
        });
    }

    /** ngOnDestroy */
    public ngOnDestroy (): void {
        this.ngZone.runOutsideAngular(() => {
            this.idle.stop();
        });
    }

}
