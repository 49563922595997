import { Injectable } from '@angular/core';
import { Helper } from '@vfde-fe/ng-modules';
import { HelperUtilsService } from './helper-utils.service';
import { ProcessService } from './process.service';

/** ValidationService */
@Injectable()
export class ValidationService {

    /** attributesMap */
    public attributesMap: any;
    // private currentStep:string;

    /**
     * constructor
     * @param helper
     * @param processService
     * @param helperUtilsService
     */
    constructor (private helper: Helper, private processService: ProcessService, private helperUtilsService: HelperUtilsService) {
        this.attributesMap = [];
    }

    // create interface attribute

    /** getAttribute */
    public getAttribute (attrName: string, isBEValidation: boolean, stepName?: string) {

        if (isBEValidation) {
            if (this.attributesMap[this.processService.getCurrentStep()] && this.attributesMap[this.processService.getCurrentStep()][attrName]) {
                return this.attributesMap[this.processService.getCurrentStep()][attrName].validationRegex || '';
            } else {
                return '';
            }
        }
        else {
            return this.helper.getRegexByKey(attrName) || '';
        }
    }

    /** addStepAttributes */
    public addStepAttributes (parametersObject: any, stepName: string) {
        // TODO add attr to the attributesMap
        // this.setCurrentStep(stepName);
        if (typeof (parametersObject) == 'object') {
            this.attributesMap[stepName] = parametersObject;
        } else if (parametersObject instanceof Array) {
            this.attributesMap[stepName] = parametersObject[0];
        } else {
            // TODO handle error
        }

    }

}
