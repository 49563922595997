import {UiWidgetModule} from '@vfde-fe/ng-modules';
import { OlLogoutFormComponent } from './widgets/components/ol-logout-form/ol-logout-form.component';
import { OlMergableAccountComponent } from './widgets/components/ol-mergable-account/ol-mergable-account.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreUiModule } from '@vfde-fe/ng-modules';
import { OlRadioTilesComponent } from './widgets/components/ol-radio-tiles/ol-radio-tiles.component';
import { OlPasswordComponent } from './widgets/components/ol-password/ol-password.component';

import { OlTextMultiComponent } from './widgets/components/ol-text-multi/ol-text-multi.component';
import { OlRadioItemComponent } from './widgets/components/ol-radio-item/ol-radio-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { OlPasswordValidationService } from './widgets/components/ol-password/ol-password-validation.service';
import { OlStepperComponent } from './widgets/components/ol-stepper/ol-stepper.component';
import { OlDropdownComponent } from './widgets/components/ol-dropdown/ol-dropdown.component';

import { OlQuestionsComponent } from './widgets/components/ol-questions/ol-questions.component';

import { UnauthorizedComponent } from './shared-components/unauthorized/unauthorized.component';
import { OlActionTrackDirective } from './widgets/directives/ol-action-track/ol-action-track.directive';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { ProcessService } from './utils/process.service';
import { ConfigLoaderService } from './utils/config-loader.service';
import { HelperUtilsService } from './utils/helper-utils.service';
import { GeneralErrorComponent } from './shared-components/general-error/general-error.component';

import { OlBurgerMenuComponent } from './widgets/components/ol-burger-menu/ol-burger-menu.component';
import { PageScrollService } from 'ngx-page-scroll';
import { LandingPageComponent } from '../home/landing-page/landing-page.component';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';

import { OlAccountListComponent } from './widgets/components/ol-account-list/ol-account-list.component';
import { OlContractItemComponent } from './widgets/components/ol-contract-item/ol-contract-item.component';
import { OlSubscriptionItemComponent } from './widgets/components/ol-subscription-item/ol-subscription-item.component';

import { OlContractProductsComponent } from './widgets/components/ol-contract-products/ol-contract-products.component';

import { OlQuestionsEditListComponent } from './widgets/components/ol-questions-edit/ol-questions-edit-list/ol-questions-edit-list.component';
import { OlQuestionsEditItemComponent } from './widgets/components/ol-questions-edit/ol-questions-edit-item/ol-questions-edit-item.component';
import { OlLinkWrapperComponent } from './widgets/components/ol-link-wrapper/ol-link-wrapper.component';
import { SplitPipe } from './widgets/pipes/split.pipe';
import { TrimPipe } from '@vfde-fe/ng-modules';
import { PaymentDatePipe } from './widgets/pipes/payment-date.pipe';
import { OlTextWrapperComponent } from './widgets/components/ol-text-wrapper/ol-text-wrapper.component';
import { MsisdnPipe } from '@vfde-fe/ng-modules';
import { ContractNamePipe } from '@vfde-fe/ng-modules';

/** SharedModule */
@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        CoreUiModule,
        UiWidgetModule,
    ],
    declarations: [
        UnauthorizedComponent,
        GeneralErrorComponent,
        OlTextWrapperComponent,
        OlRadioTilesComponent,
        OlPasswordComponent,
        OlRadioItemComponent,
        // SELECT_DIRECTIVES
        OlStepperComponent,
        OlTextMultiComponent,
        OlDropdownComponent,
        OlQuestionsComponent,
        UnauthorizedComponent,
        OlActionTrackDirective,
        GeneralErrorComponent,
        OlBurgerMenuComponent,
        OlAccountListComponent,
        OlContractItemComponent,
        OlSubscriptionItemComponent,
        OlContractProductsComponent,
        OlQuestionsEditItemComponent,
        OlQuestionsEditListComponent,
        OlMergableAccountComponent,
        OlLogoutFormComponent,
        OlLinkWrapperComponent,
        SplitPipe,
        PaymentDatePipe,
        OlLinkWrapperComponent,
    ],
    exports: [
        TranslateModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        OlTextWrapperComponent,
        OlRadioTilesComponent,
        OlPasswordComponent,
        OlRadioItemComponent,
        OlStepperComponent,
        OlTextMultiComponent,
        OlDropdownComponent,
        OlQuestionsComponent,
        OlActionTrackDirective,
        OlBurgerMenuComponent,
        OlAccountListComponent,
        OlContractItemComponent,
        OlSubscriptionItemComponent,
        OlQuestionsEditListComponent,
        OlMergableAccountComponent,
        OlLogoutFormComponent,
        CoreUiModule,
        UiWidgetModule,
        OlLinkWrapperComponent,
        SplitPipe,
        PaymentDatePipe,
    ],
    providers: [
        OlPasswordValidationService,
        ContractNamePipe,
        MsisdnPipe,
    ],

})
export class SharedModule { }
