import { config } from './../../../config/pages-config';

/** ACCOUNT_TYPES */
export const ACCOUNT_TYPES = {
    cableAccount: {
        name: 'cable',
        technicalName: 'de.vodafone.account.Cable',
    },
    mobileAccount: {
        name: 'mobile',
        technicalName: 'de.vodafone.account.Mobile',
    },
    dslAccount: {
        name: 'dsl',
        technicalName: 'de.vodafone.account.DSL',
    },
};
/** CONTRACT_TYPES_LIST */
export const CONTRACT_TYPES_LIST = {
    KAY: 'kay',
    BART: 'bart',
    MARIE: 'marie',
    ANNA: 'anna',
    PETER: 'peter',
    PAUL: 'paul',
    RALF: 'ralf',
    MARK: 'mark',
    EMMA: 'emma',
    MARTIN: 'martin',
    DONALD: 'donald',
};
/** ONB_Chooser_Step */
export const ONB_Chooser_Step = {
    login: 'login',
    registration: 'registration',
};

/** MODULE_NAMES */
export const MODULE_NAMES = {
    demoModule: 'demo',
    registrationModule: 'registration',
    addContractModule: 'addContract',
    firstLoginModule: 'firstLogin',
    loginModule: 'login',
    loginDayOneModule: 'loginDayOne',
    requestActivationCodeModule: 'requestActivationCode',
    editAccess: 'editAccess',
    forgetPassword: 'forgetPassword',
    accountManagement: 'accountManagement',
    updateEmail: 'updateEmail',
    removeEmail: 'removeEmail',
    forgetUsername: 'forgetUsername',
    mergeUserModule: 'mergeUser',
    enterpriseModule: 'enterprise',
    acceptUserInvitationModule: 'acceptUserInvitation',
    changeONBUsernameModule: 'changeONBUsername',
    paymentProviderModule: 'paymentProvider',
    gdpr: 'gdpr',
    callback: 'callBack',
    checkEmailValidation: 'checkEmailValidation',
    topupPayment: 'topupPayment',
};

/** USERNAME_OR_PASSWORD_OUTCOME_TRACKING */
export const USERNAME_OR_PASSWORD_OUTCOME_TRACKING = {
    USERNAME: 'changed_username',
    PASSWORD: 'changed_password',
    USERNAME_AND_PASSWORD: 'changed_username+pw',
};

/** PROCESS_MODULE_KEY */
export const PROCESS_MODULE_KEY = {
    REGISTRATION: 'com.uxpsystems.mint.vodafone.process.frontend.registration',
    ADD_CONTRACT: 'com.uxpsystems.mint.vodafone.process.frontend.addContract',
    LOGIN_DAY_ONE: 'com.uxpsystems.mint.vodafone.process.frontend.loginDay1',
    FIRST_LOGIN: 'com.uxpsystems.mint.vodafone.process.frontend.firstLogin',
    REQUEST_ACTIVATION_CODE: 'com.uxpsystems.mint.vodafone.process.frontend.customerActivationCodeLetter',
    EDIT_ACCESS: 'com.uxpsystems.mint.vodafone.process.frontend.revokeAccess',
    FORGET_PASSWORD: 'com.uxpsystems.mint.vodafone.process.frontend.resetPasswordRequest',
    RESET_PASSWORD_TOKEN: 'com.uxpsystems.mint.vodafone.process.frontend.resetPasswordConfirm',
    CHANGE_PASSWORD: 'com.uxpsystems.mint.vodafone.process.frontend.changePassword',
    UPDATE_EMAIL: 'com.uxpsystems.mint.vodafone.process.frontend.updateEmail',
    FORGET_USERNAME: 'com.uxpsystems.mint.vodafone.process.frontend.usernameForgotten',
    CHANGE_SECURITY_QUESTIONS: 'com.uxpsystems.mint.vodafone.process.frontend.changeSecurityQuestions',
    MERGE_USER: 'com.uxpsystems.mint.vodafone.process.frontend.mergeUser',
    ACCEPT_USER_INVITATION: 'com.uxpsystems.mint.vodafone.process.frontend.acceptUserInvitation',
    CHANGE_ONB_USERNAME: 'com.uxpsystems.mint.vodafone.process.frontend.changeONBName',
    CHECK_EMAIL: 'com.uxpsystems.mint.vodafone.process.frontend.validateEmail',
};

/** ACCEPT_USER_INVITATION */
export const ACCEPT_USER_INVITATION = {
    chooser: 'chooser',
    submitPersonalData: 'submitPersonalData',
    submitContactData: 'submitContactData',
    submitUserData: 'submitUserData',
    setSecurityQuestions: 'setSecurityQuestions',
    reviewData: 'reviewData',
    confirmation: 'confirmation',
};

/** CHANGE_ONB_USERNAME */
export const CHANGE_ONB_USERNAME = {
    changeUsername: 'changeUsername',
};

/** MERGE_USER_ERROR_CODES */
export const MERGE_USER_ERROR_CODES = {
    MERGE_NOT_POSSIBLE: 'merge-not-possible',
    MERGE_CONFLICT: 'merge-conflict',
    PRIVACY_PERMISSION_A_NOT_SET: 'privacy-permission-A-not-set',
    EMAIL_CONFLICT: 'email-conflict',

};

/** DEMO_STEPS_NAME */
export const DEMO_STEPS_NAME = {
    stepOne: 'stepOne',
    stepTwo: 'stepTwo',
    stepCustom: 'stepCustom',
};

/** Mobile_CONTRACT_TYPES */
export const Mobile_CONTRACT_TYPES = {
    EK: 'EK',
    KD: 'KD',
    TN: 'TN',
    callYa: 'callYa',
    MMO: 'MMO',
};
/** CONTRACT_TYPES_URL */
export const CONTRACT_TYPES_URL = {
    TN: 'de.vodafone.subscription.mobile.AccessSubscription',
};
/** REGISTRATION_URL */
export const REGISTRATION_URL = {
    FORUM: 'https://opweb2.vfd2-testnet.de/meinvodafone/account/login?targetURL=https:%2F%2Fopweb2.vfd2-testnet.de%2Famserver%2Fsaml2%2Fjsp%2FidpSSOInit.jsp%3FmetaAlias%3D%2Fidp%26spEntityID%3Dhttps:%2F%2Fforum.vodafone.de%2Fauth%2Fsaml%26RelayState%3Dhttps:%2F%2Fforum.vodafone.de%2Ft5%2Fuser%2Fssoregistrationpage',
};

/** REGISTRATION_TYPES */
export const REGISTRATION_TYPES = {
    tempAccount: {
        name: 'tempAccount',
    },
    contract: {
        name: 'contract',
    },
    noContract: {
        name: 'noContract',
    },
    loginForum: {
        name: 'loginForum',
    },
    registerForum: {
        name: 'registerForum',
    },
    forum: {
        name: 'forum',
    },
};

/** lastTrailCustomError_MobileContract */
export const lastTrailCustomError_MobileContract = {
    status: '400',
    operationError: {
        code: 'wrong_crdentials',
    },
};

/** ERROR_CODES */
export const ERROR_CODES = {
    REQUEST_ACTIVATION_CODE: {
        TOO_MANY_ACTIVATION_LETTERS: 'too-many-activation-letters',
        LETTER_ON_WAY: 'letter-already-on-way',
    },
    FIRST_LOGIN: {
        CREDENTIALS_EXPIRED: 'credentials-expired',
        FIRSTLOGIN_ALREADY_FINISHED: 'firstLogin-already-finished',
        USERNAME_NOT_ALLOWED: 'username-not-allowed',
    },
    REGISTRATION: {
        CONTRACT_TEMPORARY_LOCKED: 'contract-temporary-locked',
    },
    LOGIN_DAY_ONE: {
        USERNAME_NOT_AVAILABLE: 'username-not-available',
        WRONG_CREDENTIALS: 'wrong-credentials',
        WRONG_CREDENTIALS_LAST_TRIAL: 'wrong-credentials-last-trial',
        ACTIVATION_CODE_INVALID: 'activation-code-invalid',
        ACTIVATION_CODE_INVALID_LAST_TRIAL: 'activation-code-invalid-last-trial',
        USERNAME_PASSWORD_POLICY_VIOLATION: 'username-password-policy-violation',

    },
    UPDATE_EMAIL: {
        TOKEN_NOT_VALID: 'token-not-valid',
        TOKEN_EXPIRED: 'token-expired',
    },
    ADD_CONTRACT: {
        ADD_CONTRAT_BLOCKED: 'add-contract-blocked',
        CONTRACT_TEMPORARY_LOCKED: 'contract-temporary-locked',
        CONTRACT_ALREADY_EXISTS: 'contract-already-exists',
        PRIVACY_PERMISSION_NOT_ACCEPTED: 'privacy-permission-not-accepted',
    },
};

/** AUTHENTICATION_REQUIRED_ERORR */
export const AUTHENTICATION_REQUIRED_ERORR = {
    status: '401',
    error: 'authentication-required',
};

/** ACCOUNT_DETECTION */
export const ACCOUNT_DETECTION = {
    new: 'new-account',
    temp: 'temp-account',
};

/** CONTRACT_TYPES */
export const CONTRACT_TYPES = {
    mobile: {
        name: 'mobile',
    },
    dsl: {
        name: 'dsl',
    },
    cable: {
        name: 'cable',
    },
    fixednet: {
        name: 'fixednet',
    },
};

/** SECURITY_QUESTION_TYPES */
export const SECURITY_QUESTION_TYPES = {
    update: 'update',
    reuse: 'reuse',
    delete: 'delete',
};

/** USER_ACCESS_LIST_KEY */
export const IDLENESS_CONFIG_TIME: number = 900000;

/** USER_SESSION_KEY_PREFIX */
export const USER_SESSION_KEY_PREFIX = {
    data: 'ecare-LocalStore.data.',
    keys: 'ecare-LocalStore.keys',
};
/** USER_PROFILE_DETAILS */
export const USER_PROFILE_DETAILS = {
    idleness: 'idleness',
    ACL: 'ACL',
    userID: 'userID',
    mboIdsObject: 'mboIdsObject',
    singleCardFlag: 'singleCardFlag',
    singleContractFlag: 'singleContractFlag',
};

/** USER_ACCESS_LIST_KEY */
export const USER_ACCESS_LIST_KEY: string = 'ACL';

/** LOGGED_IN_USER */
export const LOGGED_IN_USER: string = 'user';

/** USER_COOKIE_KEY */
export const USER_COOKIE_KEY: string = 'MDDKeks';
// export const USER_COOKIE_KEY: string = 'mint-session-id';

/** AUTH_HINT_COOKIE_KEY */
export const AUTH_HINT_COOKIE_KEY: string = 'authHint';

/** ACCESS_LEVELS_LIST */
export const ACCESS_LEVELS_LIST = {
    ACCOUNT: 'account',
    CONTRACT: 'contract',
    SUBSCRIPTION: 'subscription',
    CONTRACT_OR_SUBSCRIPTION: 'contractOrSubscription',
};

/** TRANSACTION_STATES */
export const TRANSACTION_STATES = {
    START_TRANSACTION_STATE: 'start',
    STEP_TRANSACTION_STATE: 'step',
    END_TRANSACTION_STATE: 'end',
    SUCCESS_TRANSACTION_STATE: 'success',
    FAILURE_TRANSACTION_STATE: 'failure',
};

/** LOGIN_CONFIG */
export const LOGIN_CONFIG = {
    CLIENT_TYPE: 'Portal',
};

/** ACTORS */
export const ACTORS = {
    kay: 'kay',
    marie: 'marie',
    peter: 'peter',
    paul: 'paul',
    mark: 'mark',
    anna: 'anna',
    emma: 'emma',
    ralf: 'ralf',
    martin: 'martin',
    bart: 'bart',
    eric: 'eric',
    donald: 'donald',
};

/** MARKETCODES */
export const MARKETCODES = {
    mmc: 'MMC',
    mmo: 'MMO',
    puc: 'PUC',
    pua: 'PUA',
};

/** CONTRACTNAMES */
export const CONTRACTNAMES = {
    postpaid: 'Mobilfunk-Vertrag',
    prepaid: 'CallYa / Prepaid',
    dsl: 'DSL Vertrag',
};

/** SUBTYPES */
export const SUBTYPES = {
    ek: 'EK',
    kd: 'KD',
    tn: 'TN',
    dsl: 'DSL',

};
/** CONTRACTTYPES */
export const CONTRACTTYPES = {
    postPaid: 'post-paid',
    prePaid: 'pre-paid',
    fixedNet: 'fixedNet',
    cable: 'cable',
};

/** SUBSCRIPTION_TYPE */
export const SUBSCRIPTION_TYPE = {
    Access: 'de.vodafone.subscription.mobile.AccessSubscription',
    FemtoAccess: 'de.vodafone.subscription.mobile.FemtoAccessSubscription',
};

/** LOG_LEVELS */
export const LOG_LEVELS = {
    OFF: 0,
    ERROR: 1,
    WARN: 2,
    INFO: 3,
    DEBUG: 4,
    LOG: 5,
};

/** QUESTION_ACTIONS */
export const QUESTION_ACTIONS = {
    RELEASE: 'RELEASE',
    SELECT: 'SELECT',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    REUSE: 'REUSE',
    DISABLE_EDIT_MODE: 'DISABLE_EDIT_MODE',
};

/** email status for NIL services */
export const EMAIL_STATUS = {
    verified: 'c',
    notVerified: 'u',
};

/** email status for MINT services */
export const MINT_EMAIL_STATUS = {
    verified: 'true',
    notVerified: 'false',
};

/** FORGET_PASSWORD_CHOOSE_TYPE */
export const FORGET_PASSWORD_CHOOSE_TYPE = {
    vodafoneContratTypeName: 'vodafone-contract',
    cableContratTypeName: 'cable-contract',
    cableContratTypeId: 'cable-contract',
};

/** FORGET_PASSWORD_CONTRACT_TYPES */
export const FORGET_PASSWORD_CONTRACT_TYPES = {
    CABLE_CONTRACT: 'cable-contract',
    MOBILE_CONTRACT: 'mobile-contract',
    DSL_CONTRACT: 'dsl-contract',
    SUBMIT_SECURITY_QUESTIONS: 'submit-security-questions',
};

/** FORGET_PASSWORD_ERROR_CODES */
export const FORGET_PASSWORD_ERROR_CODES = {
    WRONG_PASSWORD: 'wrong-password',
    REMAINING_TRIES: 'add-contract-remaining-tries',
    LAST_TRIAL: 'wrong-password-last-trial',
    WRONG_ACTIVATION_CODE: 'activation-code-not-valid',
    WRONG_ACTIVATION_CODE_LAST_TRAIL: 'activation-code-not-valid-last-trial',
};

/** TECHNICAL_NAMES_FOR_MINT_MBO_IDS */
export const TECHNICAL_NAMES_FOR_MINT_MBO_IDS = {
    cableContract: {
        name: 'cable',
        technicalName: 'de.vodafone.user.activeCableAccountId',
    },
    mobileContract: {
        name: 'mobile',
        technicalName: 'de.vodafone.user.activeMobileMboId',
    },
    fixedNetContract: {
        name: 'fixedNet',
        technicalName: 'de.vodafone.user.activeDSLMboId',
    },
};

/** FORGET_USERNAME_CHOOSE_METHOD */
export const FORGET_USERNAME_CHOOSE_METHOD = {
    CONTRACT: 'contract',
    EMAIL: 'email',
};

/** CONTRACT_TYPE */
export const CONTRACT_TYPE = {
    DSL: 'dsl',
    MOBILE: 'mobile',
    CABLE: 'cable',
};
/** TOPUP_PAYMENT */
export const TOPUP_PAYMENT = {
    PRODUCT_AMOUNT: 'productAmount',
};

/** LOGIN_DAY_ONE_CONFIRM_ACCOUNT_TYPE */
export const LOGIN_DAY_ONE_CONFIRM_ACCOUNT_TYPE = {
    NO_MERGE_SAME_USERNAME: 'NO_MERGE_SAME_USERNAME',
    MERGE_SAME_USERNAME: 'MERGE_SAME_USERNAME',
    NO_MERGE_NEW_USERNAME: 'NO_MERGE_NEW_USERNAME',
};

/** FORGET_USERNAME_EROOR_CODE */
export const FORGET_USERNAME_EROOR_CODE = {
    ALTERNATIVE_PROCESS_TERMINATE: 'alternative-process-terminate',
    USED_MULTIPLE_TIMES: 'used-multiple-times',
    EMAIL_ADDRESS_NOT_VALIDATED: 'email-address-not-validated',
    INVALID_CUSTOMER_NUMBER: 'Invalid-customerNumber',
    INVALID_CUSTOMER_NUMBER_LAST_TRAIL: 'Invalid-customerNumber-last-trail',
    INVALID_CUSTOMER_NUMBER_BLOCKED: 'Invalid-customerNumber-blocked',
    WRONG_CREDENTIALS: 'wrong-credentials',
    WRONG_CREDENTIALS_LAST_TRIES: 'wrong-crdentials-last-trail',
    ADD_CONTRACT_REMAINING_TRIES: 'add-contract-remaining-tries',
};
/** LOGOUT_IDEL_CONF */
export const LOGOUT_IDEL_CONF = {
    IDEL_TIME: 20,
    TIMEOUT_TIME: 720,
    PING_INTERVAL: 15,
    TARGET_URL_ID: 'targetURL',
    LOGOUT_FORM_ID: 'logoutform',
};

/** AUTHENTICATION_ERROR_CODE */
export const AUTHENTICATION_ERROR_CODE = {
    MINT_AUTHENTICATION_REQUIRED: 'authentication-required',
    NIL_AUTHENTICATION_REQUIRED: 'NIL00A001',
};

/** AUTHORIZATION_ERROR_CODE */
export const AUTHORIZATION_ERROR_CODE = {
    SECURITY_CHALLENGE_FAILED: 'security-challenge-failed',
};

/** AUTHENTICATION_WHITELIST */
export const AUTHENTICATION_WHITELIST: Array<string> = [MODULE_NAMES.loginModule];

/** PAGE_TYPE */
export const PAGE_TYPE = {
    ONE_TIME_TRANSACTION: 'oneTimeTransaction',
};

/** DISABLE_DEEP_LINKS_WHITE_LIST */
export const DISABLE_DEEP_LINKS_WHITE_LIST: string[] = [
    config.loginDayOne.route,
    config.firstLogin.route,
    config.changeONBUsername.route,
    config.generalError.route,
];

/** LOGIN_DAY_ONE_ACCOUNT_TYPES */
export const LOGIN_DAY_ONE_ACCOUNT_TYPES = {
    vodafone: 'userDataVF',
    cable: 'userDataCable',
};

/** MERGE_ACCOUNT_TYPES */
export const MERGE_ACCOUNT_TYPES = {
    account: {
        name: 'account',
    },
    username: {
        name: 'username',
    },
};

/** LOGIN_DAY_ONE_UTILS */
export const LOGIN_DAY_ONE_UTILS = {
    REQUIRED_MESSAGE_KEY: 'loginDayOneModule.mergeMigration.infoBox.checkBox.requiredMessage.text-body',
    PRIVACY_PERMISSION_FLAG_FIELD: 'privacyPermissionFlagField',
};

/** IDENTIFICATION_TYPES */
export const IDENTIFICATION_TYPES = {
    mobile: {
        name: 'mobile',
    },
    fixedNet: {
        name: 'DSL',
    },
    cable: {
        name: 'KD',
    },
    email: {
        name: 'email',
    },
};

/** TRIM_TYPES */
export const TRIM_TYPES = {
    TRIM: 'trim',
    ALL: 'all',
};

/** DATA_TRANSFER_PERMISSION_TITLES */
export const DATA_TRANSFER_PERMISSION_TITLES = {
    PERMISSION: 'permission',
    CONFIRMATION: 'confirmation',
};

/** CABLE_PRODUCT_CATEGORIES */
export const CABLE_PRODUCT_CATEGORIES = {
    'de.vodafone.subscription.cable.TVSubscription': 'TVSubscription',
    'de.vodafone.subscription.cable.KIPSubscription': 'KIPSubscription',
    'de.vodafone.subscription.cable.3PSubscription': 'ThreePSubscription',
    'de.vodafone.subscription.cable.WHSSubscription': 'WHSSubscription',
    'de.vodafone.subscription.cable.STASubscription': 'STASubscription',
};

/** SIMPLICITY_ENV */
export const SIMPLICITY_ENV = {
    dev: 'dev',
};

/** WORDING_FILES */
export const WORDING_FILES = {
    defaultWording: 'defaultWording',
    deWording: 'deWording',
};

/** USSA_LOGIN_URL */
export const USSA_LOGIN_URL = '{domainURL}/ussa/fed/login.ftel?partnerId={context}';

/** ACCOUNT_OVERVIEW_ACCORDIONS */
export const ACCOUNT_OVERVIEW_ACCORDIONS = {
    MANAGE_ACCOUNT_DATA: 'manageAccountData',
};

/** LOGIN_SERVLET_ERROR_CODES */
export const LOGIN_SERVLET_ERROR_CODES = {
    'registration-required': config.registration.route,
    'firstLogin-required': config.firstLogin.route,
    'loginDay1-required': config.loginDayOne.route,
    'emailValidation-required': config.checkEmailValidation.route,
};

/** DEFAULT_ERROR_MESSAGE */
export const DEFAULT_ERROR_MESSAGE = {
    headline: 'Entschuldigung, da ist was schiefgelaufen.',
    text: 'Bitte versuchen Sie es sp&auml;ter noch einmal.',
    class: 'error',
    icon: 'i-error-red-sml',
};

/** PAYMENT_CONFIG */
export const PAYMENT_CONFIG = {
    USECASE: 'FulfillPayment',
    // USECASE: 'PrepayTest',
    CLIENTID: 'web',
    WEB_CHANNELID: 'meinVodafone-Web',
    MOBILE_CHANNELID: 'meinVodafone-App-webview',
    VALENCIA_MOBILE_CHANNELID: 'Valencia-App-webview',
    CURRENCY: 'EUR',
    idType: {
        ANONYMOUS: 'ANONYMOUS',
        MSISDN: 'MSISDN',
        BAN: 'BAN',
        ONLINEUSERID: 'ONLINEUSERID',
    },
    personalDataSubscriptionsRange: [2, 10],
    paymentHistoryStauts: {
        SUCCESS: 'SETTLED',
        PENDING: 'PENDING',
        FAILED: 'FAILED',
    },
    paymentHistoryType: {
        CREDITCARD: 'CREDITCARD',
        PAYPAL: 'PAYPAL',
        DIRECTEBANKING: 'DIRECTEBANKING',
    },
};

/** PAYMENT_CREATION_CONFIG */
export const PAYMENT_CREATION_CONFIG = {
    CLIENT_CODE: '44000100',
    PAYMENT_METHODS_AMOUNTS_ID: 'paymentAmount',
};

/** PAYMENT_STATUS */
export const PAYMENT_STATUS = {
    ACCEPTED: 'ACCEPTED',
    BARRED: 'BARRED',
    UNKNOWN: 'UNKNOWN',
    lIMIT_REACHED: 'lIMIT_REACHED',
    TOPUP_NOT_REACHED: 'TOPUP_NOT_REACHED',
    LIMITED: 'LIMITED',
};
/** SHOPPER_STATUS_POST */
export const SHOPPER_STATUS_POST = {
    BARRED_MIN_AMOUNT_ERROR_VALUE: 200,
    BARRED_MIN_AMOUNT_NEVER_TOPUP_BEFORE_VALUE: 1500,
};

/** SUBMIT_PAYMENT_STATUS */
export const SUBMIT_PAYMENT_STATUS = {
    AUTHORISED: 'AUTHORISED',
    PENDING: 'PENDING',
    REFUSED: 'REFUSED',
    ERROR: 'ERROR',
    CANCELLED: 'CANCELLED',
    REDIRECT_SHOPPER: 'REDIRECT_SHOPPER',
};

/** PAYMENT_ERRORS_CODE */
export const PAYMENT_ERRORS_CODE = {
    PAYER_BARRED: 'PAYER_BARRED',
    RECIEVER_BARRED: 'RECIEVER_BARRED',
    RECIEVER_UNKNOWN: 'RECIEVER_UNKNOWN',
    DOUBLE_BOOKING: ['NIL0F1002', 'NIL0F1016'],
};

/** DATA_LOSS_WHITELIST */
export const DATA_LOSS_WHITELIST = [
    config.generalError.route,
    config.logout.timeoutUrl,
];

/** MOBILE_VIEW_KEYS */
export const MOBILE_VIEW_KEYS = {
    VALENCIA: 'VALENCIA',
    MEIN_VODAFONE: 'MEIN_VODAFONE',
};
/** PAYMENT_SESSION_STORAGE */
export const PAYMENT_SESSION_STORAGE = {
    MOBILE_VIEW_KEY: 'mobileView',
    PAYMENT_KEY: 'topups',
};

/** PAYMENT_QUERYPARAMTER_HEADER */
export const PAYMENT_QUERYPARAMTER_HEADER = 'hideheader';
/** PAYMENT_QUERYPARAMTER_AMOUNT */
export const PAYMENT_QUERYPARAMTER_AMOUNT = 'product';
/** PAYMENT_MOBILE_CLASS */
export const PAYMENT_MOBILE_CLASS = 'mobile-web-view';
/** PAYMENT_MONTH */
export const PAYMENT_MONTH = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
];

/** PAYMENT_EXPIRATION_YEARS */
export const PAYMENT_EXPIRATION_YEARS = 20;

/** PAYMENT_METHODS */
export const PAYMENT_METHODS = {
    CREDITCARD: 'CREDITCARD',
    PAYPAL: 'PAYPAL',
    DIRECTEBANKING: 'DIRECTEBANKING',
};
/** SAVED_CREDITCARD */
export const SAVED_CREDITCARD = {
    CARD_NUMBER: '●●●● ●●●● ●●●● ',
    AUTHENTICATION: 'authentication',
    CVC: 'cvc',
    RightBRACKeT: '(',
    LEFTBRACKeT: ')',
};

/** CREDIT_CARD_REGEX_KEY */
export const CREDIT_CARD_REGEX_KEY = 'creditCard';

/** INTERNATIONAL_PHONE_NUMBER_REGEX_PASTE */
export const INTERNATIONAL_PHONE_NUMBER_REGEX_PASTE = 'internationalPhoneNumberRegexPaste';

/** PAYMENT_DELAY_TIME */
export const PAYMENT_DELAY_TIME = 10000;

/** ERORR_REGEX_AMOUNT */
export const ERORR_REGEX_AMOUNT = 'ERORR_REGEX_AMOUNT';

/** CSE_KEY */
export const CSE_KEY = '12378219372189398798';

/** PAYMENT_REFUSED_ERRORS */
export const PAYMENT_REFUSED_ERRORS = {
    ERROR_1: 'ERROR_1',
    ERROR_2: 'ERROR_2',
    ERROR_3: 'ERROR_3',
    ERROR_4: 'ERROR_4',
};

/** PAYMENT_REFUSED_ERROR_MAPPING */
export const PAYMENT_REFUSED_ERROR_MAPPING = {
    '3d-secure: Authentication failed': 'ERROR_1',
    'Acquirer Fraud': 'ERROR_1',
    'Blocked Card': 'ERROR_1',
    'Cancelled': 'ERROR_4',
    'CVC Declined': 'INVALID_CVC_HIGHLIGHT',
    'Refused': 'ERROR_1',
    'Declined Non Generic': 'ERROR_2',
    'Acquirer Error': 'ERROR_2',
    'Expired Card': 'ERROR_1',
    'FRAUD': 'ERROR_1',
    'FRAUD-CANCELLED': 'ERROR_1',
    'Invalid Amount': 'ERROR_2',
    'Invalid Card Number': 'INVALID_CARD_HIGHLIGHT',
    'Invalid Pin': 'ERROR_1',
    'Issuer Suspected Fraud': 'ERROR_1',
    'Issuer Unavailable': 'ERROR_1',
    'Not enough balance': 'ERROR_1',
    'Not Submitted': 'ERROR_2',
    'Not supported': 'ERROR_1',
    'Pending': 'ERROR_3',
    'Pin tries exceeded': 'ERROR_1',
    'Pin validation not possible': 'ERROR_1',
    'Referral': 'ERROR_2',
    'Restricted Card': 'ERROR_1',
    'Revocation Of Auth': 'ERROR_1',
    'Shopper Cancelled': 'ERROR_4',
    'Withdrawal count exceeded': 'ERROR_1',
    'Withdrawal amount exceeded': 'ERROR_1',
    'Transaction Not Permitted': 'ERROR_1',
    'Unknown': 'ERROR_2',
};

/** CREDIT_CARD_ERROR */
export const CREDIT_CARD_ERROR: string[] = [
    'REFUSED_BLOCK_CARD',
    'REFUSED_INVALID_CARD_HIGHLIGHT',
    'REFUSED_INVALID_CARD',
    'REFUSED_CARD_EXPIRED',
];

/** CREDIT_CARD_REFUSAL_REASON */
export const CREDIT_CARD_REFUSAL_REASON = {
    CVC_DECLINDED: 'CVC Declined',
    CVC_DECLINDED_INCORRECT: 'CVC Declined DECLINED CVC Incorrect',
    INVALID_CARD_NUMBER: 'Invalid Card Number',
};

/** AUTHORISED */
export const AUTHORISED = 'AUTHORISED';
/** NAVIGATION_DEFAULT_BROWSER */
export const NAVIGATION_DEFAULT_BROWSER = 'NAVIGATION_DEFAULT_BROWSER';
/** nbaFlagOnOff */
export const nbaFlagOnOff = {
    flagon: 'on',
    flagoff: 'off',
};

/** CALLBACK */
export const CALLBACK = {
    noCallback: 'Genesys_011',
    serviceFailed: 'Genesys_012',
    fixedNet: 'acn',
    cable: 'cable',
    subscription: 'subscription',
    noMboName: 'Teilnehmer',
    updateUrl: '/hilfe-support/rueckruf.html#/neubuchung?Id=',
    deleteUrl: '/hilfe-support/rueckruf.html#/callbackLoeschen?Id=',
    callbackAccordionId: 'callback',
};
/** ONELOGIN_URL */
export const ONELOGIN_URL = 'meinvodafone/account';
/** EMMA_MARTIN_ERRORS_IN_FORGET_PASSWORD */
export const EMMA_MARTIN_ERRORS_IN_FORGET_PASSWORD = {
    EMMA_UNIQUE_NOT_MIGRATED_VERFIED: 'emma-unique-not-migrated-verfied',
    EMMA_UNIQUE_NOT_MIGRATED_NOT_VERFIED: 'emma-unique-not-migrated-not-verfied',
    EMMA_MARTIN_NOT_UNIQUE_NOT_MIGRATED: 'emma-martin-not-unique-not-migrated',
};

/** VISITOR_BUSINESS_TYPES */
export const VISITOR_BUSINESS_TYPES = {
    CHOOSER: 'chooser',
    USER: 'user',
};
/** CHECK_EMAIL_VALIDATION */
export const CHECK_EMAIL_VALIDATION = {
    STEP_NAME: {
        VALIDATE_EMAIL_STEP: 'validate-email',
    },
    CHECK_EMAIL_STEP_NAME: {
        VALIDATE_EMAIL_COMPONENT: 'validate-email',
        RESEND_CONFIRMATION_EMAIL: 'resend email confirmation',
        CHANGE_EMAIL: 'change email',
        ERROR_MESSAGE: 'error-message',
    },
};

/** SESSION_STORAGE_ECARE_KEY */
export const SESSION_STORAGE_ECARE_KEY = 'ecare';

/** SESSION_STORAGE_SIMS_KEY */
export const SESSION_STORAGE_SIMS_KEY = 'sims';

/** SESSION_STORAGE_SIMS_OBJ_KEY */
export const SESSION_STORAGE_SIMS_OBJ_KEY = 'simsObj';

/** KABEL_MAIL_DOMAIN */
export const KABEL_MAIL_DOMAIN = '@kabelmail.de';

/** PAYMENT_CLOSE_BTN */
export const PAYMENT_CLOSE_BTN = 'common.buttons.close.std-headline';

/** PAYMENT_CANCEL_BTN */
export const PAYMENT_CANCEL_BTN = 'common.buttons.cancel.std-headline';

/** Lold payment */
export const LOGOUT_URL_FOR_PAYMENT = config.login.route + '?goto=' + config.paymentProvider.personalData.route;

/** MAILBOX_TYPES */
export const MAILBOX_TYPES = {
    OX: 'OX',
    PEGASOS: 'PEGASOS',
    NONE: 'NONE',
};
/** NAVIGATION_TARGET */
export const NAVIGATION_TARGET = {
    NEW_TAB : '_newTab',
};
