var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { Http } from '@angular/http';
import { CoreConfigurationsLoader, regex } from '@vfde-fe/ng-modules';
import { of } from 'rxjs';
import { config } from '../../../config/pages-config';
import { environment } from '../../../environments/environment';
/** ConfigLoaderService */
var ConfigLoaderService = /** @class */ (function (_super) {
    __extends(ConfigLoaderService, _super);
    /**
     * constructor
     * @param injector
     */
    function ConfigLoaderService(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        var currentUrl = window.location.href;
        var availableContext = currentUrl.match(regex.context);
        _this.context = availableContext ? availableContext[1] : '';
        return _this;
    }
    /**
     * loads all configuration files at once by trying getting it from cms first then it gets the local one if it failed
     * the configutation files includes the following:
     * error-message - the error messages file
     * common.error - the common error messages for the general error
     * defaultWording - the local default wording file
     * deWording - the german wording file on CMS
     *             it first checks if context available and tries getting it,
     *             if it failed to load the context-dewording file for any reason it tries getting the general deWording
     */
    ConfigLoaderService.prototype.load = function () {
        var _this = this;
        var errorsConfig = new Promise(function (resolve, reject) {
            setTimeout(function () {
                var http = _this.injector.get(Http);
                http.get(environment.cmsConfigPath + 'error-messages.json').subscribe(function (response) {
                    try {
                        _this.errors = response.json();
                        resolve(_this.errors);
                    }
                    catch (error) {
                        console.warn('not valid json for error-messages.json file');
                        http.get(environment.configPath + 'error-messages.json').subscribe(function (response) {
                            _this.errors = response.json();
                            resolve(_this.errors);
                        });
                    }
                }, function (error) {
                    http.get(environment.configPath + 'error-messages.json').subscribe(function (response) {
                        _this.errors = response.json();
                        resolve(_this.errors);
                    });
                });
            });
        });
        var commonErrorsConfig = new Promise(function (resolve, reject) {
            setTimeout(function () {
                var http = _this.injector.get(Http);
                http.get(environment.cmsErrorMessagesPath + 'common.error.js').subscribe(function (response) {
                    try {
                        _this.commonErrors = response.json();
                        resolve(_this.commonErrors);
                    }
                    catch (error) {
                        console.warn('not valid json for common.error.js file');
                        http.get(environment.configPath + 'common.error.json').subscribe(function (response) {
                            _this.commonErrors = response.json();
                            resolve(_this.commonErrors);
                        });
                    }
                }, function (error) {
                    http.get(environment.configPath + 'common.error.json').subscribe(function (response) {
                        _this.commonErrors = response.json();
                        resolve(_this.commonErrors);
                    });
                });
            });
        });
        var defaultGermanWording = new Promise(function (resolve, reject) {
            setTimeout(function () {
                var http = _this.injector.get(Http);
                http.get(environment.configPath + 'i18n/defaultWording.json').subscribe(function (response) {
                    _this.defaultGermanWordingfile = response.json();
                    resolve(_this.defaultGermanWordingfile);
                });
            });
        });
        var deWording = new Promise(function (resolve, reject) {
            setTimeout(function () {
                var http = _this.injector.get(Http);
                if (_this.context) {
                    http.get(environment.cmsConfigPath + 'i18n/deWording-' + _this.context + '.json').subscribe(function (response) {
                        try {
                            _this.deWordingfile = response.json();
                            resolve(_this.deWordingfile);
                        }
                        catch (error) {
                            console.warn('not valid json for deWording' + _this.context + '.json file');
                        }
                    }, function (err) {
                        http.get(environment.cmsConfigPath + 'i18n/deWording.json').subscribe(function (response) {
                            try {
                                _this.deWordingfile = response.json();
                                resolve(_this.deWordingfile);
                            }
                            catch (error) {
                                console.warn('not valid json for deWording.json file');
                            }
                        }, function (err) {
                            resolve(null);
                        });
                    });
                }
                else {
                    http.get(environment.cmsConfigPath + 'i18n/deWording.json').subscribe(function (response) {
                        try {
                            _this.deWordingfile = response.json();
                            resolve(_this.deWordingfile);
                        }
                        catch (error) {
                            console.warn('not valid json for deWording.json file');
                        }
                    }, function (err) {
                        resolve(null);
                    });
                }
            });
        });
        var coreDefaultGermanWording = new Promise(function (resolve, reject) {
            setTimeout(function () {
                var http = _this.injector.get(Http);
                http.get(environment.configPath + 'i18n/defaultWording-core.json').subscribe(function (response) {
                    _this.coreDefaultGermanWording = response.json();
                    resolve(_this.coreDefaultGermanWording);
                }, function (error) {
                    resolve();
                });
            });
        });
        var coreWording = new Promise(function (resolve, reject) {
            setTimeout(function () {
                var http = _this.injector.get(Http);
                http.get(environment.cmsCommonWording).subscribe(function (response) {
                    try {
                        _this.commonWording = response.json();
                        resolve(_this.commonWording);
                    }
                    catch (error) {
                        console.warn('not valid json for core-deWording.json file');
                    }
                }, function (err) {
                    resolve(null);
                });
            });
        });
        return Promise.all([errorsConfig, commonErrorsConfig, defaultGermanWording, deWording, coreWording, coreDefaultGermanWording]);
    };
    /** loadPagesRoutes */
    ConfigLoaderService.prototype.loadPagesRoutes = function () {
        return config;
    };
    /** getcommonErrorsConfig */
    ConfigLoaderService.prototype.getcommonErrorsConfig = function () {
        return this.commonErrors;
    };
    /** getErrorsConfig */
    ConfigLoaderService.prototype.getErrorsConfig = function () {
        return this.errors;
    };
    /** getDefaultGermanWordingFile */
    ConfigLoaderService.prototype.getDefaultGermanWordingFile = function () {
        return of(Object.assign(this.defaultGermanWordingfile, this.coreDefaultGermanWording));
    };
    /** getDeWordingFile */
    ConfigLoaderService.prototype.getDeWordingFile = function () {
        return of(Object.assign(this.deWordingfile || {}, this.commonWording));
    };
    /** setCoreConfigRoute */
    ConfigLoaderService.prototype.setCoreConfigRoute = function () {
        this.generalError = this.loadPagesRoutes()['generalError'].route;
        this.unauthorized = this.loadPagesRoutes()['unauthorized'].route;
    };
    return ConfigLoaderService;
}(CoreConfigurationsLoader));
export { ConfigLoaderService };
