import { Injectable, Injector } from '@angular/core';
import { Http } from '@angular/http';

/** ConfigWhitelistService */
@Injectable()
export class ConfigWhitelistService {

  private http: Http;
  private whitelist: any;
  constructor (private injector: Injector) { }

}
